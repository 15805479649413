@include imgBoxRight($class:"karriere-detail", $colors: $green);

.karriere-detail {

  .karriere-detail-wrapper {
    margin: 80px auto;
    background-color: $grey-lighter;
    padding: 80px 0;
  }

  .karriere-detail-inner {
    max-width: $max-width;
    margin: 0 auto;
  }

  .karriere-detail-image {
    max-width: 460px;
  }

  .karriere-detail-info {
    &-divider {
      height: 4px;
      background-color: $grey-dark;
      margin: 30px 0 25px 0;
    }
    &-kontakte {
      color: $grey-dark;
      font: 14px/17px $klavikaBold;
      padding: 5px 0;
    }
    &-street {
      @include contactTxt();
      @include contactPin();
    }
    &-phone {
      @include contactTxt();
      @include contactPhone();
    }
    &-email {
      margin-bottom: 5px;
      @include contactTxt();
      @include contactEmail();
    }
  }

}

@media only screen and (min-width: 1280px) {
  .karriere-detail {

    .karriere-detail-image {
      margin-left: 170px;
    }
  }
}


@media only screen and (max-width: $responsive) {
  .karriere-detail {

    .karriere-detail-wrapper {
      padding: 50px 0 80px 0;
    }

    .karriere-detail-info {
      height: auto;
    }

  }
}

@media only screen and (max-width: 375px) {
  .karriere-detail {

    .karriere-detail-wrapper {
      margin: 80px auto;
    }

    .karriere-detail-inner {
      max-width: $max-width;
      margin: 0 auto;
    }


  }
}