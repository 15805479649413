.locations-map-container {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
}
.gmap-wrap {
  position: relative;
  width: 100%;
  height: calc(100vh - 185px);
}

.map-box1 {

  .map-box1-wrapper {

  }

  .map-box1-inner {
    position: relative;
    max-width: 1280px;
    margin: auto;
    height: 700px;
    background: url('/fileadmin/user_upload/site_assets/map.svg') no-repeat right center;
  }

  .map-box1-box {
    position: absolute;
    top: 280px;
    height: 340px;
    width: 540px;
    background-color: $grey-lighter;
    border-top: 4px solid $blue;
    display: flex;
    margin-left: 163px;
    //@include squareRight($color: $blue);
  }
  .map-box1-layer {
    align-self: center;
    text-align: right;
    margin: 30px;
  }
  .map-box1-title {
    @include boxTitle();
    margin-bottom: 10px;
  }
  .map-box1-text {
    @include boxText();
    margin: 10px 0;
  }
  .map-box1-link {
    @include boxLink();
  }

}

@media only screen and (max-width: 1024px) {
  .map-box1 {

    .map-box1-inner {
      padding: 0 10px;
    }

    .map-box1-box {
      top: 60%;
      left: 50%;
      transform: translateX(-50%);
      margin-left: unset;
    }

  }
}

@media only screen and (max-width: 768px) {
  .map-box1 {

    .map-box1-inner {
      background-position: 20% 50%;
    }

    .map-box1-box {
      height: auto;
      max-width: 400px;
      width: 90%;
      margin: auto;
    }
    .map-box1-layer {
      text-align: left;
      margin: 30px 20px;
    }
    .map-box1-title {
      margin-bottom: 10px;
      margin-top: 0px;
    }

  }
}

@media only screen and (max-width: 640px) {
  .map-box1 {

    .map-box1-inner {
      background-position: 30% 50%;
    }

  }
}
