.img-gallery {

  .img-gallery-wrapper {
    padding: 50px 0;
  }

  .img-gallery-inner {
    margin: auto;
  }

  .img-gallery-grid {
    display: grid !important;
    grid-template-columns: 220px 220px 220px 220px 220px;
    grid-gap: 45px;
    max-width: $max-width;
    margin: auto;
  }
  .slick-list {
    max-width: 1280px;
    margin: auto;
  }
  .slick-slide {
    height: auto;
  }
  .img-gallery-img-wrapper {
    position: relative;
    &:hover {
      cursor: pointer;
      .img-gallery-overlay {
        opacity: 0.7;
      }
      &:after {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: sassvg('ZoomRegular',  #ffffff) center no-repeat;
      }
    }

  }

  .img-gallery-overlay {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #623412;

  }

}

.img-gallery-overview {
  &-img-wrapper {
    position: relative;

    h3 {
      margin-bottom: 0;
      color: $grey-light;
    }

    p.img-gallery-overview-label {
      margin: 0;
      font-size: 0.8em;
      font-weight: bold;
      color: $grey-light;

      &:before {
        content: "";
        background: sassvg('ArrowSmallMidgrey', $grey-light) no-repeat;
        display: inline-block;
        width: 20px;
        height: 10px;
      }
    }
  }
  a:hover {
    text-decoration: none;
  }
}

@media only screen and (max-width: 1300px) {
  .img-gallery {

    .img-gallery-grid {
      max-width: 460px;
      grid-template-columns: 220px 220px;
    }
    .slick-list {
      max-width: 460px;
    }
  }
}


@media only screen and (max-width: 640px) {
  .img-gallery {

    .img-gallery-grid {
      max-width: 354px;
      grid-template-columns: 172px 172px;
      grid-gap: 10px;
    }
    .slick-list {
      max-width: 354px;
    }
    .slick-slide img {
      max-width: 172px;
    }
  }
}