.team-banner {

  .team-banner-wrapper {
    margin: 80px auto;
  }

  .team-banner-inner {
    max-width: $max-width;
    max-height: 281px;
    overflow: hidden;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 462px 1fr;
  }
  .team-banner-box {
    padding: 20px 40px;
    background-color: $green;
    display: flex;
    align-items: center;
    &-title {
      margin: 0 0 10px 0;
      color: #ffffff;
      font: 18px $klavikaBold;
    }
    &-text {
      margin: 10px 0;
      color: #ffffff;
      font: 28px/34px $klavikaLight;
    }
    &-link {
      color: #ffffff;
      font: 12px $klavikaBold;
      text-transform: uppercase;
    }
  }

  .box-blue {
    background-color: $blue;
  }
  .box-green {
    background-color: $green;
  }
  .box-red {
    background-color: $red;
  }
  .box-purple {
    background-color: $purple;
  }
  .box-yellow {
    background-color: $yellow;
  }
  .box-brown {
    background-color: $brown;
  }
}

@media only screen and (max-width: 1023px) {
  .team-banner {

    .team-banner-wrapper {
      padding: 50px 0;
    }

    .team-banner-inner {
      grid-template-columns: 1fr;
    }

    .team-banner-image {
      display: none;
    }
  }
}

@media only screen and (max-width: 768px) {
  .team-banner {
    .team-banner-inner {
      margin: 0 10px;
    }
  }
}

@media only screen and (max-width: 375px) {
  .team-banner {

    .team-banner-wrapper {
      margin: 80px auto;
    }

    //.team-banner-inner {
    //  max-width: $max-width;
    //  margin: 0 auto;
    //}


  }
}