.text-video {

  .text-video-wrapper {

  }

  .text-video-inner {
    max-width: $max-width;
    margin: auto;
}

  .text-video-container {
    display: grid;
    grid-template-columns: 1fr 380px;
    grid-gap: 40px;
  }

  .text-video-img {
    max-width: 380px;
  }
  .text-video-text {
    font: 18px/28px $klavikaLight;
    color: $grey-light;
    @include marginsElementSet;
  }
  .text-video-label {
    margin-top: 7px;
    font: 12px $klavika;
    color: $grey-light;
    padding-left: 20px;
  }
  .text-video-right {
    padding-top: 7px;
  }

  @include bullet-list();

}

@media only screen and (max-width: 1023px) {
  .text-video {

    .text-video-wrapper {

    }

    .text-video-inner {
      padding: 0 10px;
    }

    .text-video-container {
      grid-template-columns: 1fr;
    }

  }
}