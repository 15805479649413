.header-search-result {

  .header-search-result-wrapper {
    padding-bottom: 80px;
  }
  .header-search-result-overlay {
    display: flex;
    flex-flow: column;
  }
  .header-search-result-inner {
    height: 200px;
    width: 100%;
    margin: auto;
    background-color: $grey-header;
  }
  .header-search-result-position {
    max-width: 1280px;
    margin: auto;
    width: 100%;
  }
  .header-search-result-box {
    position: relative;
    width: 460px;
    padding: 30px;
    background-color: $grey;
    margin-top: -60px;
    //@include squareTop($color: $grey-lighter);
    .header-search-result-box-title {
      margin: 0;
      color: #ffffff;
      font: 18px $klavikaBold;
      background-size: 24px;
    }
    .header-search-result-box-text {
      color: #ffffff;
      font: 28px $klavikaLight;
      margin: 10px 0;
    }
    .header-search-result-box-line {
      height: 2px;
      width: 60px;
      background-color: #FFFFFF;
    }

  }


}

@media only screen and (max-width: $responsive) {
  .header-search-result {

    .header-search-result-wrapper {
      padding-bottom: 90px;
    }
    .header-search-result-box {
      max-width: 600px;
      //@include squareTop($color: $green);
    }

  }
}

@media only screen and (max-width: 768px) {
  .header-search-result {

    .header-search-result-position {
      max-width: 1280px;
      margin: auto;
      width: 100%;
    }
    .header-search-result-box {
      margin-right: 10px;
      margin-left: 10px;
      width: auto;
      max-width: unset;
    }
  }
}
