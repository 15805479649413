$icons-box-size: 200px;

.icons1 {
  border-top: 4px solid $green;
  &.no-border {
    border-top: none !important;
    .icons1-wrapper.outer-margin {
      padding: 0 0 30px 0;
    }
  }
  .icons1-wrapper {

  }

  .icons1-inner {
    max-width: $max-width;
    margin: auto;
  }

  .icons1-boxes {
    display: grid;
    grid-gap: 80px;
    grid-template-columns: $icons-box-size $icons-box-size $icons-box-size;
    justify-content: center;
  }

  .icons1-title {
    font: 18px $klavikaBold;
    color: $grey-dark;
    text-align: center;
    margin-bottom: 10px;
  }

  .icons1-text {
    font: 28px/38px $klavikaLight;
    color: $grey-dark;
    text-align: center;
    margin-bottom: 40px;
  }

  .icons1-box {
    background-color: #ffffff;

    a {
      display: block;
      height: 100%;
      &:hover {
        text-decoration: none;
      }
    }
    &:hover {
      box-shadow: 0 0 5px 0 rgba(0,0,0,0.3);
      .icons1-box-newsletter {
        visibility: visible;
      }
    }
    &-img {
      line-height: 0;
      text-align: center;
    }
    &-title {
      text-align: center;
      font: 18px $klavikaBold;
      color: $grey-light;
    }
    &-text {
      text-align: center;
      font: 14px/17px $klavikaLight;
      color: $grey-light;
    }
    &-link-container {
      padding: 40px 30px 20px 30px;
    }
    &-newsletter {
      visibility: hidden;
      font: 14px/17px $klavikaBold;
      color: $grey;
      text-transform: uppercase;
      text-align: center;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .icons1 {
    .icons1-box-newsletter {
      visibility: visible;
    }
  }
}

@media only screen and (max-width: 767px) {
  .icons1 {

    .icons1-boxes {
      grid-template-columns: 240px;
      grid-row-gap: 10px;
    }
  }
}

@media only screen and (max-width: 375px) {
  .icons1 {

    .icons1-box {
      background-color: #ffffff;
      padding: 40px 20px 20px 20px;
    }

  }
}