// ----------------------------
// VARIABLES
$white: #FFFFFF;
$grey: #686869;
$grey-header: #DFE3E6;
$grey-dark: #313131;
$grey-light: #7C7E7F;
$grey-lighter: #F7F7F7;
$green: #008522;
$purple: #590776;
$yellow: #FFB500;
$brown: #623412;
$red: #D50032;
$blue: #003DA5;
$blue-transparent: rgba(0, 61, 165, 0.6);
$green-transparent: rgba(0, 133, 34, 0.6);
$purple-transparent: rgba(89, 7, 118, 0.6);
$yellow-transparent: rgba(255, 181, 0, 0.8);
$brown-transparent: rgba(98, 52, 18, 0.6);
$red-transparent: rgba(213, 0, 50, 0.6);
$grey-transparent-header: rgba(223, 227, 230, 0.6);
$blue-dark: #00348E;
$box-colors: (
        "box-blue": $blue-transparent,
        "box-green": $green-transparent,
        "box-red": $red-transparent,
        "box-purple": $purple-transparent,
        "box-yellow": $yellow-transparent,
        "box-brown": $brown-transparent,
        "box-grey": $grey-transparent-header,
);
$box-classes: "box-blue" "box-green" "box-purple" "box-yellow" "box-red"  "box-brown" "box-grey";

/*** FONTS ***/
@font-face {
  font-family: KlavikaRegular;
  src: url(../Fonts/KlavikaBasic-Regular.otf);
  src: url(../Fonts/KlavikaBasic-Bold.woff) format('woff');
}
@font-face {
  font-family: KlavikaBold;
  src: url(../Fonts/KlavikaBasic-Bold.otf);
  src: url(../Fonts/KlavikaBasic-Bold.woff) format('woff');
}
@font-face {
  font-family: KlavikaLight;
  src: url(../Fonts/KlavikaBasic-Light.otf);
  src: url(../Fonts/KlavikaBasic-Light.woff) format('woff');
}
@font-face {
  font-family: KlavikaLightItallic;
  src: url(../Fonts/KlavikaBasic-LightItalic.otf);
}
@font-face {
  font-family: KlavikaMedium;
  src: url(../Fonts/KlavikaBasic-Medium.otf);
  src: url(../Fonts/KlavikaBasic-Medium.woff) format('woff');
}
$klavika: KlavikaRegular;
$klavikaBold: KlavikaBold;
$klavikaLight: KlavikaLight;
$klavikaMedium: KlavikaMedium;
$klavikaLightItallic: KlavikaLightItallic;

//KlavikaBasic-Bold.otf
//KlavikaBasic-Light.otf
//KlavikaBasic-LightItalic.otf
//KlavikaBasic-Medium.otf
//KlavikaBasic-MediumItalic.otf
//KlavikaBasic-Regular.otf
//MinionPro-Regular.otf

$mobile-item-height: 64px;
$desktop-width: 1080px;
$max-width: 1280px;


/*** BREAKPOINTS ***/
$responsive: 1024px;
$mobile: 768px;
$smallDevices: 375px;



// ----------------------------
// VARIABLES
// Content widths
$container-width: 1180px;
$container-width-narrow: 800px;
$ce-margins: 30px auto 30px auto;
// Menu switch
$menuswitch: 1024px;


// Font colors
$title-color: #3068B4;
$text-color: #4E5256;
// Page grid widths
$sidebar-left-2-container: 200px;
$sidebar-right-2-container: 200px;
$sidebar-left-3-container: 200px;
$sidebar-right-3-container: 300px;
$sidebar-left-2-container-fluid: 230px;
$sidebar-right-2-container-fluid: 200px;
$sidebar-left-3-container-fluid: 200px;
$sidebar-right-3-container-fluid: 300px;
$content-padding: 0 20px; // Used for default page padding on mobile
$content-padding-mobile: 0 20px; // Used for default page padding on mobile
$base-p-space: 20px;
$a-link-color: #000;
$a-hover-active-color: #d00;
$a-visited-color: #666;
//Header
$header-padding-column: 0;
$header-border: 1px solid #D7E3EF;
$header-height: 70px;
$header-contact-color: #3068B4;
$header-language-color: #4E5256;
// Navigation
$nav-padding-main: 0; // Used as padding for main navigation
$nav-popup-min-width: 220px;
$nav-popup-background: #ddd;
$nav-popup-border: 1px solid #bbb;
$nav-popup-border-radius: 2px;
//Colors
$color-orange: #F49128;
$blue-background: #EAF1F6;

// Mobile navigation Slideout
$slideout-padding-mobile: 0px; // Used as padding for mobile slideout
$slideout-width: 360px;
$slideout-padding: 30px;
// SECTIONS VARS
$section-width-container-narrow: $container-width-narrow;
$section-width-container-normal: $container-width;
$section-width-content-narrow: $container-width-narrow;
$section-width-content-normal: $container-width;
$section-padding-top-small: 20px;
$section-padding-top-middle: 50px;
$section-padding-top-large: 100px;
$section-padding-bottom-small: 20px;
$section-padding-bottom-middle: 50px;
$section-padding-bottom-large: 100px;
$section-margin-top-small: 20px;
$section-margin-top-middle: 50px;
$section-margin-top-large: 100px;
$section-margin-bottom-small: 20px;
$section-margin-bottom-middle: 50px;
$section-margin-bottom-large: 100px;
