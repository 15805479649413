@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {

  body {
    overflow: auto;
  }

  .landing-page {
    display: none !important;
  }

  .menu-trigger-label {
    top: 30px;
    transform: none;
  }

  .header {
    &-container {
      display: -ms-grid;
      -ms-grid-columns: 600px 1fr;
    }
    &-nav {
      -ms-grid-column: 1;
    }
    &-logo {
      -ms-grid-column: 2;
    }
    &-search-form {
      .field {
        margin-top: 24px;
      }
    }
  }

  .hamburger-container-inner {
    display: -ms-grid;
    -ms-grid-columns: 720px 1fr;
    .menu-hamburger {
      -ms-grid-column: 1;
    }
    .header-search {
      -ms-grid-column: 2;
    }
  }

  .fact {
    &-boxes {
      display: flex;
      flex-wrap: wrap;
      & > div:first-child {
        margin-left: 0;
      }
      & > div:last-child {
        margin-right: 0;
      }
    }
    &-box {
      margin: 10px;
    }
  }

  .color-boxes {
    &-outer {
      display: -ms-grid;
      -ms-grid-columns: 240px 1fr;
    }
    &-info {
      -ms-grid-column: 1;
    }
    &-boxes {
      display: -ms-grid;
      -ms-grid-column: 2;
      -ms-grid-columns: 230px 230px 230px;
      & > a {
        display: block;
        margin: 0 10px 0 10px;
      }
      @for $i from 1 through 3 {
        & > a:nth-child(#{$i}) {
          -ms-grid-row: 1;
          -ms-grid-column: $i;
          margin-bottom: 20px;
        }
        & > a:nth-child(#{$i+3}) {
          -ms-grid-row: 2;
          -ms-grid-column: $i;
        }
        & > a:nth-child(1) {
          margin-left: 0;
        }
        & > a:nth-child(3) {
          margin-right: 0;
        }
        & > a:nth-child(4) {
          margin-left: 0;
        }
        & > a:nth-child(6) {
          margin-right: 0;
        }
      }
    }
  }

  .industry-boxes {
    .industry-boxes-boxes {
      display: -ms-grid;
      -ms-grid-columns: 233px 233px 233px 233px;
      max-width: 1280px;
      margin: auto;
      @for $i from 1 through 4 {
        & > div:nth-child(#{$i}) {
          -ms-grid-row: 1;
          -ms-grid-column: $i;
        }
        & > div:nth-child(#{$i+4}) {
          -ms-grid-row: 2;
          -ms-grid-column: $i;
        }
      }
    }
    .industry-boxes-box {
      height: 183px;
    }

  }

  .img-box2 {
    .slick-prev {
      left: auto;
    }
  }

  .icons1 {
    .icons1-boxes {
      display: -ms-grid;
      -ms-grid-columns: 240px 280px 240px;
      max-width: 762px;
      margin: auto;
      @for $i from 1 through 3 {
        & > div:nth-child(#{$i}) {
          -ms-grid-column: $i;
        }
      }
    }
    .icons1-box {
      margin: 0 40px;
      &:last-child {
        margin-right: 0;
      }
      &:first-child {
        margin-left: 0;
      }
    }

  }

  .img-gallery {
    .img-gallery-grid {
      & > a {
        float: left;
        margin: 0 20px 20px 0;
      }
      & > a:nth-child(4n) {
        margin-right: 0px;
      }
      &:after {
        display: block;
        content: '';
        height: 0;
        clear: both;
      }
    }
  }

  .team-grid {
    .team-grid-search {
      &-holder {
        display: flex;
        & > div {
          margin-right: 20px;
        }
      }
    }
    .team-grid-order-holder {
      display: -ms-grid;
      -ms-grid-columns: 1fr 150px;
    }
    .team-grid-alphabet {
      -ms-grid-column: 1;
    }
    .team-grid-sort {
      -ms-grid-column: 2;
    }
    .team-grid-single {
      &-holder.sort-grid {
        margin-left: -10px;
        margin-right: -10px;
        & > div {
          float: left;
          margin: 0 10px 20px 10px;
        }
        &:after {
          display: block;
          content: '';
          height: 0;
          clear: both;
        }
      }
      &-holder.sort-list {
        -ms-grid-columns: 310px 310px 310px;
        &.display-grid {
          display: -ms-grid !important;
        }
        & > div {
          margin-right: 20px;
        }
        @for $i from 1 through 3 {
          & > div:nth-child(#{$i}) {
            -ms-grid-row: 1;
            -ms-grid-column: $i;
          }
        }
        & > div:last-child {
          margin-right: 0px;
        }
      }
    }
  }

  .karriere-grid {
    .karriere-grid-single {
      &-item {
        height: 157px;
      }
      &-holder.sort-grid {
        margin-left: -10px;
        margin-right: -10px;
        & > div {
          width: 300px;
          height: 300px;
          float: left;
          margin: 0 10px 20px 10px;
        }
        &:after {
          display: block;
          content: '';
          height: 0;
          clear: both;
        }
      }
    }
    .karriere-grid-search {
      &-holder {
        display: -ms-grid;
        -ms-grid-columns: 1fr 180px;
      }
    }
    .karriere-grid-item {
      -ms-grid-column: 1;
      display: flex;
      & > div {
        margin-right: 20px;
      }
      & > div:last-child {
        margin-right: 0;
      }
    }
    .karriere-grid-sort {
      -ms-grid-column: 2;
    }
  }

  .news-grid  {
    .news-grid-single {
      &-item {
        height: 157px;
      }
      &-holder.sort-grid {
        margin-left: -10px;
        margin-right: -10px;
        & > div {
          width: 300px;
          height: 300px;
          float: left;
          margin: 0 10px 20px 10px;
        }
        &:after {
          display: block;
          content: '';
          height: 0;
          clear: both;
        }
      }
    }
    .news-grid-search {
      &-holder {
        display: -ms-grid;
        -ms-grid-columns: 1fr 180px;
      }
    }
    .news-grid-item {
      -ms-grid-column: 1;
      display: flex;
      & > div {
        margin-right: 20px;
      }
      & > div:last-child {
        margin-right: 0;
      }
    }
    .news-grid-sort {
      -ms-grid-column: 2;
    }
  }

  .map-box-list {
    .map-box-list-cities {
      float: right;
      display: flex;
      max-width: 220px;
      flex-wrap: wrap;
      & > a {
        width: 100px;
        margin-right: 10px;
      }
    }
  }

  .map-box3 {
    .map-box3-grid {
      display: -ms-grid;
      -ms-grid-columns: 220px 1fr;
      & > div:first-child {
        margin-right: 20px;
      }
      @for $i from 1 through 2 {
        & > div:nth-child(#{$i}) {
          -ms-grid-column: $i;
        }
      }
    }
  }

  #cboxLoadedContent {
    overflow: hidden !important;
  }

  .footer {
    .footer-inner {
      display: -ms-grid;
      -ms-grid-columns: auto auto;
      margin: auto;
      padding-top: 45px;
      @for $i from 1 through 2 {
        & > div:nth-child(#{$i}) {
          -ms-grid-column: $i;
        }
      }
    }
  }
}

@media all and (-ms-high-contrast: none) and (max-width: 1024px), (-ms-high-contrast: active) {

  .header-container-m {
    z-index: 5;
  }

  .mobile-header-nav {
    padding-bottom: 20px;
  }

  .mobile-header-nav-link-level-0 {
    display: block !important;
  }

  .color-boxes {
    &-outer {
      display: block;
    }
    &-boxes {
      -ms-grid-columns: 210px 210px 210px;
    }
  }

  .img-box1 {
    .img-box1-info {
      top: auto;
      bottom: auto;
      right: auto;
      left: auto;
      transform: none;
      max-width: 420px;
    }
    .img-box1-holder {
      -ms-flex-align: center;
    }
  }

  .img-box2 {
    .img-box2-info {
      top: auto;
      bottom: auto;
      right: auto;
      left: auto;
      transform: none;
      //max-width: 420px;
    }
    .img-box2-holder {
      -ms-flex-align: center;
    }
    .img-box2-industry {
      position: relative;
      top: auto;
      left: 0;
    }
    .slick-arrow {
      top: auto;
    }
  }

  .img-form {
    .img-form-info {
      top: auto;
      bottom: auto;
      right: auto;
      left: auto;
      transform: none;
    }
    .img-form-holder {
      -ms-flex-align: center;
    }
  }

  .map-box1 {
    .map-box1-box {
      margin-left: -270px;
      transform: none;
    }
  }

  .team-grid {
    .team-grid-single {
      &-holder.sort-grid {
        max-width: 640px;
        margin-left: auto;
        margin-right: auto;
        //& > div {
        //  &:nth-child(even){
        //    margin-right: 0;
        //  }
        //  &:nth-child(odd){
        //    margin-right: 20px;
        //  }
        //}
      }
      &-holder.sort-list {
        display: -ms-grid;
        -ms-grid-columns: 260px 260px 260px;
      }
    }
  }

  .karriere-grid {
    .karriere-grid-single {
      &-holder.sort-grid {
        max-width: 640px;
        margin-left: auto;
        margin-right: auto;
        & > div {
          &:nth-child(even){
            margin-right: 0;
          }
          &:nth-child(odd){
            margin-right: 20px;
          }
        }
      }
    }
    .karriere-grid-item-select {
      max-height: 32px;
    }
  }

  .news-grid{
    .news-grid-single {
      &-holder.sort-grid {
        max-width: 640px;
        margin-left: auto;
        margin-right: auto;
      }
    }
    .news-grid-item-select {
      max-height: 32px;
    }
  }

  .karriere-detail {
    .karriere-detail-info {
      top: auto;
      bottom: auto;
      left: auto;
      right: auto;
      transform: none;
    }
  }

  .team-grid {
     .team-grid-single {
       &-holder.sort-list.display-grid {
         display: block !important;
       }
     }
   }

  .footer {
    .footer-inner {
      display: block;
    }
  }

  use {
    display: none;
  }
}

@media all and (-ms-high-contrast: none) and (max-width: 1023px), (-ms-high-contrast: active) {
  .fact {
    &-boxes {
      & > div:first-child {
        margin-left: 10px;
      }
      & > div:last-child {
        margin-right: 10px;
      }
    }
    &-box {

    }
  }

  .industry-boxes {
    .industry-boxes-boxes {
      -ms-grid-columns: 233px 233px 233px;
      max-width: 700px;
      @for $i from 1 through 3 {
        & > div:nth-child(#{$i}) {
          -ms-grid-row: 1;
          -ms-grid-column: $i;
        }
        & > div:nth-child(#{$i+3}) {
          -ms-grid-row: 2;
          -ms-grid-column: $i;
        }
        & > div:nth-child(#{$i+6}) {
          -ms-grid-row: 3;
          -ms-grid-column: $i;
        }
      }
    }

  }

  .img-gallery {
    .img-gallery-grid {
      max-width: 460px;
      width: 460px;
      & > a:nth-child(odd){
        margin-left: 0;
      }
      & > a:nth-child(even){
        margin-right: 0;
      }
    }
  }

}

@media all and (-ms-high-contrast: none) and (max-width: 768px), (-ms-high-contrast: active) {
  .color-boxes {
    &-boxes {
      -ms-grid-columns: 177px 177px;
      max-width: 350px !important;
      & > a {
        height: 167px;
        margin: 0 10px 0 10px;
      }
      & > a:nth-child(odd) {
        margin-left: 0;
        margin-right: 10px;
      }
      & > a:nth-child(even) {
        margin-right: 0;
        margin-left: 10px;
      }
      @for $i from 1 through 2 {
        & > a:nth-child(#{$i}) {
          -ms-grid-row: 1;
          -ms-grid-column: $i;
          margin-bottom: 20px;
        }
        & > a:nth-child(#{$i+2}) {
          -ms-grid-row: 2;
          -ms-grid-column: $i;
          margin-bottom: 20px;
        }
        & > a:nth-child(#{$i+4}) {
          -ms-grid-row: 3;
          -ms-grid-column: $i;
        }
      }
    }
  }

  .industry-boxes {
    .industry-boxes-boxes {
      -ms-grid-columns: 183px 183px;
      max-width: 366px;
      @for $i from 1 through 2 {
        & > div:nth-child(#{$i}) {
          -ms-grid-row: 1;
          -ms-grid-column: $i;
        }
        & > div:nth-child(#{$i+2}) {
          -ms-grid-row: 2;
          -ms-grid-column: $i;
        }
        & > div:nth-child(#{$i+4}) {
          -ms-grid-row: 3;
          -ms-grid-column: $i;
        }
        & > div:nth-child(#{$i+6}) {
          -ms-grid-row: 4;
          -ms-grid-column: $i;
        }
      }
    }
  }
}

@media all and (-ms-high-contrast: none) and (max-width: 480px), (-ms-high-contrast: active) {
  .color-boxes {
    &-boxes {
      max-width: 167px !important;
      display: block;
      & > a {
        width: 167px;
        margin: 0 10px 10px 10px !important;
      }
      & > a:last-child {
        margin-bottom: 10px;
      }
    }
  }
  .industry-boxes {
    .industry-boxes-boxes {
      display: block;
      max-width: 183px;
    }
  }
}