@mixin font($family: null,
            $weight: null,
            $color: null,
            $size: null,
            $line: null,
            $space: null,
            $style: null) {

  @if ($family) {
    font-family: $family;
  }
  @if ($weight) {
    font-weight: $weight;
  }
  @if ($color) {
    color: $color;
  }
  @if ($size) {
    font-size: $size;
  }
  @if ($line) {
    line-height: $line;
  }
  @if ($space) {
    letter-spacing: $space;
  }
  @if ($style) {
    font-style: $style;
  }

}

@mixin bullet-list ($color-bullet: $green){

  @if ($color-bullet == ""){
    $color-bullet: $green;
  }

  ul {
    margin-left: 30px;
    li {
      position: relative;
      padding-left: 30px;
      font: 18px/28px $klavikaLight;
      color: $grey;
      margin-top: 15px;
      &:before {
        position: absolute;
        top: 5px;
        left: 0;
        width: 14px;
        height: 14px;
        background-color: $color-bullet;
        content: '';
      }

    }
  }
}

@mixin marginsElementSet(){
  & > p, & > h1, & > h2, & > h3 {
    margin-top: 0;
    margin-bottom: 15px;
  }
  & > p:last-child,
  & > h1:last-child,
  & > h2:last-child,
  & > h3:last-child {
    margin-bottom: 0px;
  }
}

@mixin link-style($color:null) {

  @if ($color == null) {
    $color: $grey-dark;
  }

  @include font($family: $klavikaBold,
                $color: $color,
                $size: 12px,
                $line: 15px);

}

@mixin text($color:null) {

  @if ($color == null) {
    $color: $grey;
  }

  color: $color;
  font-size: 18px;
  letter-spacing: 0.2px;
}

@mixin border-top ($color:null){
  @if($color == null) {
    $color: $blue
  }
  border-top: 4px solid $color;
}

@mixin squareRight ($color:null) {
  @media only screen and (min-width: 1025px) {
    $square-size: 28px;
    &:after {
      position: absolute;
      bottom: -$square-size;
      right: -$square-size;
      background-color: $color;
      height: $square-size;
      width: $square-size;
      content: '';
    }
  }
}

@mixin squareTop ($color:null) {
  @media only screen and (min-width: 1025px) {
    $square-size: 40px;
    &:after {
      position: absolute;
      top: -$square-size;
      right: -$square-size;
      background-color: $color;
      height: $square-size;
      width: $square-size;
      content: '';
    }
  }
}

@mixin squareLeft ($color){
  @media only screen and (min-width: 1025px) {
    $square-size: 28px;
    &:after {
      position: absolute;
      bottom: -$square-size;
      left: -$square-size;
      background-color: $color;
      height: $square-size;
      width: $square-size;
      content: '';
    }
  }

}

@mixin hamburger ($class: null, $left: null, $el-to-show: null){


  // Hamburger
  .#{$class}-trigger {
    position: absolute;
    clip: rect(0, 0, 0, 0);
  }

  // Format and add Animation for Hamburger (x instead of =)
  .#{$class}-trigger-label {
    position: absolute;
    width: 25px;
    height: 18px;
    transition: .5s ease-in-out;
    cursor: pointer;
    z-index: 5;
    top: 50%;
    transform: translateY(-50%);
    @if($left){
      left: $left;
    }
    span {
      display: block;
      position: absolute;
      height: 1px;
      width: 100%;
      border-radius: 1px;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: .25s ease-in-out;
      background: $grey;
    }
    span:nth-child(1) {
      top: 0px;
      transform-origin: left center;
    }
    span:nth-child(2) {
      top: 7px;
      transform-origin: left center;
    }
    span:nth-child(3) {
      top: 14px;
      transform-origin: left center;
    }
  }

  // Move item to the left
  .#{$class}-trigger:checked + .#{$class}-trigger-label {

    span:nth-child(1) {
      transform: rotate(45deg);
      top: -2px;
      left: 4px;
    }
    span:nth-child(2) {
      width: 0%;
      opacity: 0;
    }
    span:nth-child(3) {
      transform: rotate(-45deg);
      top: 16px;
      left: 4px;
    }
  }

  .#{$class}-trigger:checked ~ #{$el-to-show} {
    transition: all 0.1s linear;
    display: block;
    opacity: 1;
  }

}

@mixin imgBoxRight($class:null, $colors: null){
  .#{$class} {

    .#{$class}-inner {
      display: flex;
      justify-content: flex-start;
      position: relative;
    }
    .#{$class}-image {

    }
    .#{$class}-holder {
      position: relative;
      line-height: 0;
      //@include squareRight ($color: $colors);
    }
    .#{$class}-info {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -480px;
      height: 341px;
      width: 540px;
      padding: 30px;
      background-color: #ffffff;
      line-height: 1;
      @include border-top($color: $colors);
      //@include squareRight ($green);
      &-title {
        margin-top: 0;
        text-align: left;
        font: 18px $klavikaBold;
      }
      &-text {
        text-align: left;
        font: 28px $klavikaLight;
        margin: 10px 0;
      }
      &-link {
        text-align: left;
        margin-top: 20px;
        padding: 0;
        @include link-style;
      }
      &-container {
        display: flex;
        justify-content: flex-start;
        height: 100%;
        &-inner {
          margin: auto 0;
        }
      }

      &-socials {
        position: absolute;
        bottom: -40px;
        left: 100px;
        display: flex;
        align-items: center;
        & > div {
          //display: inline-block;
          margin: 0 15px;
        }
        &-text {
          margin-left: 0 !important;
          font: 14px $klavikaBold;
          color: $grey-light;

        }
        svg {
          transition: opacity 300ms;
          &:hover {
            opacity: 0.7;
          }
        }
      }
    }


  }

  @media only screen and (min-width: 1280px) {
    .#{$class} {

      .#{$class}-holder {
        margin-left: 170px; // centering img-box
      }
    }
  }


  @media only screen and (max-width: $responsive) {
    .#{$class} {

      .#{$class}-wrapper {

      }

      .#{$class}-inner {
        justify-content: center;
        //padding-bottom: 250px;
      }

      //.#{$class}-image {
      //  width: 100%;
      //  height: auto;
      //}
      .#{$class}-holder {
        display: flex;
        flex-flow: column-reverse;
      }
      .#{$class}-info {
        position: relative;
        top: unset;
        bottom: unset;
        left: unset;
        right: unset;
        transform: unset;
        margin: -70px auto 0 auto;
        width: 96%;
        max-width: 390px;
        height: auto;
        //&-title {
        //  font: 14px $klavikaBold;
        //}
        &-title {
          text-align: left;
          margin-top: 0;
        }
        &-text {
          text-align: left;
          font-size: 24px;
        }
        &-link {
          float: none;
        }
        &-container {
          justify-content: flex-start;
          &-inner {
            width: 100%;
          }
        }
        &-socials {
          bottom: -40px;
          left: 0px;
          padding-left: 30px;
          & > div {
            margin: 0 10px;
          }
        }
      }

    }

  }


  @media only screen and (max-width: 375px) {
    .#{$class} {

      .#{$class}-info {

        &-socials {
          bottom: -60px;
          & > div {
            margin: 0 5px;
          }
          &-text {
            display: block !important;
          }
        }
      }

    }
  }
}

@mixin contactTxt ($color:#686869) {
  font: 14px/17px $klavikaLight;
  padding: 5px 0 5px 28px;
  color: $color;
}
@mixin contactPhone($color:$grey-dark) {
  background: sassvg('ContactPhoneWhite', $color) no-repeat center left;
  background-size: 13px 14px;
}
@mixin contactFax($color:$grey-dark) {
  background: sassvg('FaxDarkgrey', $color) no-repeat center left;
  background-size: 13px 14px;
}
@mixin contactEmail($color:$grey-dark) {
  background: sassvg('ContactMailWhite', $color) no-repeat center left;
  background-size: 13px 12px;
}
@mixin contactPin($color:$grey-dark) {
  background: sassvg('PinRed', $color) no-repeat center left;
  background-size: 12px 16px;
}
@mixin contactPDF($color:$grey-dark) {
  background: sassvg('MailDarkgreyLock', $color) no-repeat center left;
  background-size: 17.5px 12px;
}

@mixin boxTitle (){
  font: 18px $klavikaBold;
  color: $grey-dark;
}
@mixin boxText (){
  font: 28px/38px $klavikaLight;
  color: $grey-dark;
}
@mixin boxLink (){
  display: inline-block;
  font: 12px $klavikaBold;
  color: $grey-dark;
  text-transform: uppercase;
}