.text-imgr {

  .text-imgr-wrapper {

  }

  .text-imgr-inner {
    max-width: $max-width;
    margin: auto;
    //padding: 0 10px;
  }

  .text-imgr-container {
    display: grid;
    grid-template-columns: 1fr 380px;
    grid-gap: 40px;
  }

  .text-imgr-img {
    max-width: 380px;
  }
  .text-imgr-text {
    font: 18px/28px $klavikaLight;
    color: $grey-light;
    @include marginsElementSet;
  }
  .text-imgr-label {
    font: 12px $klavika;
    color: $grey-light;
    padding-left: 20px;
  }
  .text-imgr-right {
    padding-bottom: 7px;
  }

  @include bullet-list();
}

@media only screen and (max-width: 1023px) {
  .text-imgr {

    .text-imgr-wrapper {

    }

    .text-imgr-inner {
      padding: 0 10px;
    }

    .text-imgr-container {
      grid-template-columns: 1fr;
    }

  }
}