.map-box-list {

  .map-box-list-wrapper {
    position: relative;
    margin: auto;
    max-width: 1280px;
  }

  .map-box-list-inner {
    position: relative;
    margin: auto;
  }
  .map-box-list-container-inner {
    display: grid;
  }
  .map-box-list-box {
    position: absolute;
    bottom: 40px;
    left: 0;
    height: auto;
    width: 540px;
    background-color: $grey-lighter;
    border-top: 4px solid $red;
    padding: 30px;
    z-index: 1;
  }

  .map-box-list-position {
    margin: auto;
    max-width: 1280px;
  }

  .map-box-list-title {
    @include boxTitle();
    text-align: right;
    margin-bottom: 10px;
    margin-top: 0px;
  }
  .map-box-list-text {
    @include boxText();
    text-align: right;
    margin: 0 0 20px 0;
  }

  .map-box-list-cities {
    display: grid;
    grid-template-columns: 100px 100px;
    grid-gap: 0 40px;
    justify-self: flex-end;
  }

  .map-box-list-city {
    font: 12px/15px $klavikaBold;
    text-transform: uppercase;
    color: $grey-dark;
    padding: 7px 0;
    margin:0;
  }
  //.map-box-list-link {
  //  @include boxLink();
  //}
  //
  //
  //&-holder {
  //  float: left;
  //  &-right {
  //    margin-left: 70px;
  //  }
  //}
  //&-divider {
  //  height: 4px;
  //  background-color: $grey-dark;
  //  margin: 30px 0 25px 0;
  //}
  //&-kontakte {
  //  color: $grey-dark;
  //  font: 14px/17px $klavikaBold;
  //  padding: 5px 0;
  //}
  //&-street {
  //  @include contactTxt();
  //  @include contactPin();
  //}
  //&-street2 {
  //  @include contactTxt();
  //}
  //&-city {
  //  @include contactTxt();
  //}
  //&-phone {
  //  @include contactTxt();
  //  @include contactPhone();
  //}
  //&-fax {
  //  @include contactTxt();
  //  @include contactFax();
  //}
  //&-email {
  //  @include contactTxt();
  //  @include contactEmail();
  //  margin-bottom: 10px;
  //}
  //&-link {
  //
  //}

}

// Fixes footer margin for google maps
.page12,
.page36 {
  .footer-wrapper {
    margin-top: 0 !important;
  }
}

@media only screen and (min-width: 1440px) {
  .map-box-list {

    .map-box-list-box {
      bottom: 60px;
      padding: 70px 30px;
    }
  }
}

@media only screen and (max-width: 1023px) {
  .map-box-list {

    .map-box-list-wrapper {

    }

    .map-box-list-inner {
      padding: 0 10px;
    }


  }
}

@media only screen and (max-width: 768px) {
  .map-box-list {

    .map-box-list-wrapper {
      display: flex;
      flex-flow: column;
    }

    .map-box-list-inner {
      width: 100%;
    }
    .map-box-list-box {
      position: relative;
      top: unset;
      bottom: unset;
      left: unset;
      height: auto;
      max-width: 400px;
      width: 90%;
      margin: auto;
      margin-top: -80px;
      transform: unset;
      z-index: 1;
      padding: 30px;
    }
    .map-box-list-layer {
      text-align: left;
      margin: 10px 30px;
    }
    &-holder {
      float: none;
      &-right {
        margin-left: 0px;
      }
    }

    .map-box-list-title {
      margin-bottom: 10px;
      margin-top: 0px;
      text-align: left;
    }
    .map-box-list-text {
      text-align: left;
    }
    .map-box-list-cities {
      justify-self: flex-start;
    }
  }
  .page12,
  .page36 {
    .footer-wrapper {
      margin-top: 30px !important;
    }
  }
}
