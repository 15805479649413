@include imgBoxRight("img-box2", $colors: $yellow);

.img-box2 {

  .img-box2-wrapper {
    margin: 80px auto;
    background-color: $grey-lighter;
    padding: 80px 0;
  }

  .img-box2-inner {
    max-width: $max-width;
    margin: 0 auto;
  }

  .img-box2-holder {
    width: 460px;
  }

  .img-box2-info {
    &-link {
      text-transform: uppercase;
    }
    &-text {
      color: $grey-dark;
    }
    &-title {
      a {
        color: $grey-dark;
      }
    }
  }
  .img-box2-industry {
    position: absolute;
    top: -40px;
    left: 100px;
    color: $grey-light;
    font: 14px $klavikaBold;
    text-transform: uppercase;
  }

  .slick-slide{
    padding: 0px 0px 29px 0px;
  }
  .slick-list {
    max-width: $max-width;
    margin: auto;
  }
  .slick-arrow {
    top: 76%;
    width: 28px;
    height: 26px;
  }
  .slick-next {
    right: 50%;
    transform: translateX(63px);
    background: sassvg("NextRegular", $grey-light) no-repeat center;
    &:hover {
      background: sassvg("NextRegular", $grey-dark) no-repeat center;
    }
  }
  .slick-prev {
    right: 50%;
    transform: translateX(25px);
    left: unset;
    background: sassvg("NextHover", $grey-light) no-repeat center;
    &:hover {
      background: sassvg("NextHover", $grey-dark) no-repeat center;
    }
  }
}


@media only screen and (max-width: $responsive) {
  .img-box2 {

    .img-box2-wrapper {
      padding: 70px 0 110px 0;
    }

    .img-box2-image {
      max-width: 462px;
      margin: auto;
    }
    .img-box2-holder {
      width: auto;
      max-width: 462px;
      margin: auto;
    }
    .slick-arrow {
      top: unset;
      bottom: 80px;
    }
    .slick-next {
      left: 51%;
      right: unset;
      transform: translateX(20%);
    }
    .slick-prev {
      right: 51%;
      left: unset;
      transform: translateX(-20%);

    }
    
    .img-box2-industry {
      margin-bottom: 15px;
      position: unset;
    }

  }
}


@media only screen and (max-width: 375px) {
  .img-box2 {

    .img-box2-wrapper {
      margin: 80px auto;
      padding: 70px 0 80px 0;
    }

    .img-box2-inner {
      max-width: $max-width;
      margin: 0 auto;
    }

    .slick-arrow {
      top: unset;
      bottom: 6%;
    }

    .img-box2-info {
      height: auto;
      &-link {
        background: none;
        border: none;
        float: none;
      }
    }

  }
}