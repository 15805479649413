.blog-detail {
  &--container {
    margin: 0 17px;
    @media (min-width: $responsive) {
      max-width: $container-width;
      margin: 0 auto;
    }
  }

  &--header {
    margin-bottom: 80px;
    @media (max-width: $responsive) {
      margin-left: -17px;
      margin-right: -17px;
    }

    &--image {
      object-position: top;
    }

    &--content-container {
      display: flex;
      align-items: center;
      gap: 12px;
      max-width: $max-width;
      margin: -120px 17px 0;
      position: relative;

      @media (min-width: 970px) { //using this value to line up category color box and rest of the page
        margin: -74px auto 0 auto;
      }

      .breadcrumb-holder {
        display: none;
        @media (min-width: $responsive) {
          display: flex;
          position: static;
        }
      }
    }

    &--category-container {
      position: relative;
      width: 100%;
      height: 240px;
      padding: 33px 42px 33px 33px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;

      @media (min-width: $mobile) {
        height: auto;
        min-height: 186px;
        max-width: 460px;
      }
    }

    &--title {
      font: 18px KlavikaBold;
      color: $white;
      margin: 0;

      .box-grey & {
        color: $text-color;
      }
    }

    &--post-name {
      font: 28px KlavikaLight;
      color: $white;
      margin: 15px 0;

      .box-grey & {
        color: $text-color;
      }
    }

    &--divider {
      width: 83px;
      height: 3px;
      background: $white;
      margin: 0;
      border: none;

      .box-grey & {
        background: $text-color;
      }
    }

    &--box {
      display: none;

      @media (min-width: $mobile) {
        display: block;
        position: absolute;
        padding: 20px;
        top: -40px;
        right: -40px;
      }
    }
  }

  &--content {
    max-width: $max-width;
    margin: 0 auto;

    .author-wrapper {
      padding-top: 0;
    }

    &--container {
      display: grid;
      grid-template-columns: auto;
      gap: 60px;
      flex-direction: column;
      margin: 30px 0 80px;

      @media (min-width: $responsive) {
        grid-template-columns: 2fr 1fr;
        gap: 30px;
      }
    }

    &--left {
      display: flex;
      flex-direction: column;
      gap: 40px;
    }

    &--right {
      @media (min-width: $responsive) {
        max-width: 293px;
        justify-self: flex-end;
      }
    }
  }

  &--text {
    font: 18px KlavikaRegular;
    line-height: 28px;
    margin: 0;

    &--container {
      margin: 0;
    }

    &--date {
      margin: 0;
      color: $title-color;
    }

    &--text {
      color: $grey-light;
    }
  }

  &--categories-tags-container {
    display: grid;
    grid-template-columns: auto;
    place-items: flex-start center;
    gap: 20px;

    @media (min-width: $responsive) {
      grid-template-columns: 1fr 1fr;
    }
  }

  &--categories,
  &--tags {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    flex-wrap: wrap;
    width: 100%;
    @media (min-width: $responsive) {
      align-items: center;
    }
  }

  &--categories {
    justify-content: flex-start;
  }

  &--tags {
    justify-content: flex-start;
    @media (min-width: $responsive) {
      justify-content: flex-end;
    }
  }

  &--single-category {
    width: 100%;
    height: 100%;
    max-width: calc(84px - 23px);
    max-height: calc(28px - 14px);
    font: 12px KlavikaLight;
    padding: 7px 17px 7px 7px;
    color: $white;
    position: relative;
    margin: 0;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: calc(100% - 6px);
      background: sassvg('ArrowWhite', $white) no-repeat right center;
      background-size: 6px auto !important;
    }

    &.box-grey {
      color: $text-color;

      &:before {
        background: sassvg('ArrowWhite', $text-color) no-repeat right center;
        background-size: 5px auto !important;
      }
    }
  }

  &--single-tag {
    max-height: calc(28px - 14px);
    font: 12px KlavikaLight;
    padding: 7px;
    background: $grey-header;
    margin: 0;
  }

  &--related {
    &--container {
      max-width: $max-width;
      margin: 0 auto 130px;
    }

    &--title {
      font: 38px KlavikaLight;
      color: $grey-light;
      margin: 0 0 52px 0;
    }

    &--list {
      display: grid;
      gap: 30px;
      grid-template-columns: auto;

      @media (min-width: $mobile) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media (min-width: $responsive) {
        grid-template-columns: repeat(3, 1fr);
      }
    }

    &--single {
      min-height: 240px;
      padding: 36px 30px 30px;
      background: $grey-lighter;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      gap: 20px;

      &--title {
        font: 18px KlavikaBold;
        margin: 0;
        color: $grey-light;
        @media (min-width: $mobile) {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }

      &--text-content {
        margin: 0;
      }

      &--text {
        display: inline-block;
        font: 14px KlavikaLight;
        line-height: 20px;
        color: $text-color;
        @media (min-width: $mobile) {
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          line-height: 24px;
        }
      }

      &--date {
        font: 14px KlavikaRegular;
        color: $title-color;
        line-height: 24px;
      }

      &--link {
        font: 14px KlavikaBold;
        line-height: 14px;
        text-decoration: underline;
        color: $red;
      }

      &.box-red {
        border-top: 6px solid $red;
      }

      &.box-blue {
        border-top: 6px solid $blue;
      }

      &.box-green {
        border-top: 6px solid $green;
      }

      &.box-yellow {
        border-top: 6px solid $yellow;
      }

      &.box-purple {
        border-top: 6px solid $purple;
      }

      &.box-brown {
        border-top: 6px solid $brown;
      }

      &.box-grey {
        border-top: 6px solid $grey-header;
      }
    }
  }
}
