$box-height-desktop: 360px;
$box-height-mobile: 320px;

.blog-post {
  &--header {

    &--container {
      position: relative;
      padding-bottom: $box-height-desktop / 6;

      @media (max-width: $responsive) {
        padding-bottom: $box-height-mobile / 2;
      }
    }

    &--image {
      display: block;
      line-height: 1;
    }

    &--content-container {
      margin: 0 auto;
      position: absolute;
      max-width: $max-width;
      left: 0;
      right: 0;
      bottom: 0;
      @media (min-width: $responsive) {
        margin: 0 auto;
      }
    }

    &--text-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 20px;
      padding: 25px 40px 30px;
      width: auto;
      height: $box-height-mobile;
      bottom: 0;
      position: absolute;
      right: 20px;
      left: 20px;

      @media (min-width: $responsive) {
        height: $box-height-desktop;
        right: 0;
        left: unset;
        width: 560px;
        bottom: -40px;
      }
    }

    &--title {
      font: 36px KlavikaLight;
      color: $white;
      margin: 0;
      line-height: 100%;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    &--text-content {
      margin: 0;
    }

    &--date {
      color: $white;
      font-size: 14px;
      font-weight: 300;
      line-height: 24px;
      margin: 0;
    }

    &--text {
      color: $white;
      font-size: 14px;
      font-weight: 300;
      line-height: 24px;
      margin: 0;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    &--divider-line {
      background: $white;
      height: 3px;
      width: 83px;
      margin: 0;
      border: none;
    }

    &--link {
      color: $white;
      font: 14px KlavikaBold;
      line-height: 14px;
      text-decoration: underline;
      margin: 0;
    }

    &--box {
      position: absolute;
      padding: 25px;
      bottom: -50px;
      right: -50px;
      @media (min-width: $responsive) {
        padding: 38px;
        bottom: -76px;
        right: -76px;
      }
    }
  }

  &--detailed {
    padding: 120px 0 150px;
    max-width: $max-width;
    margin: 0 17px;
    @media (max-width: $responsive) {
      padding: 60px 0 50px;
    }
    @media (min-width: $responsive) {
      margin: 0 auto;
    }
  }

  &--actions-container {
    width: 100%;
    max-width: $max-width;
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;
    gap: 30px;
    @media (min-width: $responsive) {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }

  &--category {
    &--container {
      display: flex;
      flex-direction: column;
      gap: 15px;
      @media (min-width: $responsive) {
        max-width: 272px;
      }
    }

    &--title {
      color: $text-color;
      font: 18px KlavikaBold;
      line-height: 22px;
      margin: 0;
    }

    &--list {
      display: flex;
      flex-wrap: wrap;
      gap: 8px 8px;
    }

    &--item {
      width: 100%;
      height: 100%;
      max-width: calc(85px - 25px);
      max-height: calc(28px - 14px);
      color: $white;
      padding: 7px 17px 7px 8px;
      margin: 0;
      position: relative;
      font: 12px KlavikaLight;
      cursor: pointer;

      &:before {
        content: '';
        position: absolute;
        right: 8px;
        top: 0;
        width: calc(100% - 8px);
        height: 100%;
        background: sassvg('ArrowWhite', $white) no-repeat right center;
        background-size: 5px auto !important;
        z-index: 0;
      }
    }

    &--item.active {
      max-width: calc(84px - 27px);
      max-height: calc(28px - 16px);
    }

    &--item.box-grey {
      color: $text-color;

      &:before {
        background: sassvg('ArrowWhite', $text-color) no-repeat right center;
        background-size: 5px auto !important;
      }
    }

    &--item.box-blue.active {
      background: none;
      border: 1px solid #003DA5;
      color: $blue;

      &:before {
        background: sassvg('ArrowWhite', $blue) no-repeat right center;
      }
    }

    &--item.box-green.active {
      background: none;
      border: 1px solid #008522;
      color: $green;

      &:before {
        background: sassvg('ArrowWhite', $green) no-repeat right center;
      }
    }

    &--item.box-purple.active {
      background: none;
      border: 1px solid #590776;
      color: $purple;

      &:before {
        background: sassvg('ArrowWhite', $purple) no-repeat right center;
      }
    }

    &--item.box-red.active {
      background: none;
      border: 1px solid #D50032;
      color: $red;

      &:before {
        background: sassvg('ArrowWhite', $red) no-repeat right center;
      }
    }

    &--item.box-yellow.active {
      background: none;
      border: 1px solid #FFB500;
      color: $yellow;

      &:before {
        background: sassvg('ArrowWhite', $yellow) no-repeat right center;
      }
    }

    &--item.box-brown.active {
      background: none;
      border: 1px solid #623412;
      color: $brown;

      &:before {
        background: sassvg('ArrowWhite', $brown) no-repeat right center;
      }
    }

    &--item.box-grey.active {
      background: none;
      border: 1px solid #686869;
      color: $grey;

      &:before {
        background: sassvg('ArrowWhite', $grey) no-repeat right center;
      }
    }
  }

  &--tags {
    &--container {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }

    &--title {
      color: $text-color;
      font: 18px KlavikaBold;
      line-height: 22px;
      margin: 0;
    }

    &--list {
      display: flex;
      flex-wrap: wrap;
      gap: 8px 8px;
    }

    &--item {
      background: #DBDFE2;
      max-height: calc(28px - 14px);
      color: $text-color;
      padding: 7px;
      margin: 0;
      position: relative;
      font: 12px KlavikaLight;
      cursor: pointer;
    }

    &--item.active {
      color: $white;
      background: $title-color;
    }
  }

  &--single-post {
    &--list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 30px 30px;

      @media (max-width: 768px) {
        grid-template-columns: auto;
        gap: 50px;
      }

      &--no-items {
        display: none;
      }
    }

    &--container {
      width: 100%;
      height: 100%;
      position: relative;
      //margin-bottom: 20px;
    }

    &--image {
      object-fit: cover;
      display: block;
      @media (min-width: 769px) {
        max-height: 285px;
      }
    }

    &--text-container {
      position: relative;
      //width: calc(100% - 45px);
      background: $grey-lighter;
      padding: 15px;
      display: flex;
      flex-direction: column;
      gap: 25px;
      justify-content: space-between;
      //margin-top: -30px;
      //margin-left: 45px;

      @media (min-width: $mobile) {
        padding: 30px 70px 40px 30px;
        height: 260px;
        //position: absolute;
        //bottom: -215px;
        //left: 45px;
        margin: 0;
      }
    }

    &--text-container.box-red {
      border-top: 6px solid $red;
    }

    &--text-container.box-blue {
      border-top: 6px solid $blue;
    }

    &--text-container.box-green {
      border-top: 6px solid $green;
    }

    &--text-container.box-yellow {
      border-top: 6px solid $yellow;
    }

    &--text-container.box-purple {
      border-top: 6px solid $purple;
    }

    &--text-container.box-brown {
      border-top: 6px solid $brown;
    }

    &--text-container.box-grey {
      border-top: 6px solid $grey-header;
    }

    &--text-content {
      margin: 0;
    }

    &--title {
      margin: 0;
      font: 18px KlavikaBold;
      color: $grey-light;
      @media (min-width: $mobile) {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }

    &--date {
      margin: 0;
      font: 14px KlavikaRegular;
      color: $title-color;
      line-height: 24px;
    }

    &--text {
      display: inline-block;
      margin: 0;
      font: 14px KlavikaLight;
      color: $text-color;
      line-height: 20px;
      @media (min-width: $mobile) {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        line-height: 24px;
      }
    }

    &--link {
      font: 14px KlavikaBold;
      line-height: 14px;
      text-decoration: underline;
      color: $red;
    }
  }
}

.filter-item--inactive {
  display: none !important;
}