@mixin fadeInAnimation($delay){
    animation: fade-in 2s ease $delay+s;
    animation-fill-mode: forwards;
}

.landing-page {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $white;
  z-index: 3;

  &-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: url('/fileadmin/user_upload/site_assets/landing-page.jpg') no-repeat;
    background-size: cover;
    opacity: 0;

    animation-fill-mode: forwards;
    animation-name: fade-in;
    animation-delay: 1s;
    animation-duration: 2s;
  }

  &-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    max-width: 600px;
    height: 490px;
    transform: translate(-50%, -50%);
  }

  &-header {
    position: absolute;
    width: 100%;
    height: 40px;
    background: sassvg('MB-LogoBig', $grey-light) no-repeat center;
    background-size: contain;

    // animation
    will-change: transform;
    top: 150px;
    opacity: 0;
    animation: header-up 1.5s ease-out 1.1s;
    animation-fill-mode: forwards;
  }
  &-text {
    position: absolute;
    width: 100%;
    font: 18px/38px $klavikaLight;
    color: $grey-dark;
    text-align: center;

    // animation
    will-change: transform;
    top: 300px;
    opacity: 0;
    animation: paragraph-up 1.7s ease 1s;
    animation-fill-mode: forwards;
    &-bold {
      font-family: $klavikaBold;
    }
  }

  &-squares {
    position: absolute;
    bottom: 140px;
    left: 50%;
    transform: translateX(-50%);
    display: grid;
    grid-template-columns: repeat(6, 14px);
    grid-gap: 10px;
    justify-content: center;
    height: 14px;
    &-wrapper {
      position: relative;
    }
  }
  &-square {
    position: absolute;
    left: 0;
    width: 14px;
    height: 14px;

    // animation
    will-change: transform;
    opacity: 0;

    @each $class-name, $bgcolor in $box-colors {
      &.#{$class-name} {
        background-color: $bgcolor;
      }
    }

    &.box-blue {
      top: 200px;
      animation: square-up 1s ease 1.5s;
      animation-fill-mode: forwards;
    }

    @for $i from 2 through length($box-classes) {
      $class-name: nth($box-classes, $i);
      $delayTime: 1.5 + $i;
      &.#{$class-name} {
        top: 0px;
        @include fadeInAnimation($delay: $delayTime);
      }
    }

  }

  &-scroll-wrapper {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -60px;
    width: 30px;
    height: 45px;
    will-change: transform;
    opacity: 0;
    animation: scroll-wrapper-up 1s ease 2.5s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in;
  }

  &-scroll {
    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
    height: 45px;
    cursor: pointer;
    background: sassvg('PublicationScrollindicator', $grey-light) no-repeat center;
    transform: translateY(-10px);
    animation: up-down 2s linear 3s infinite;
  }

  &.remove-landing-page {
    opacity: 1;
    animation: fade-out 2s ease 1s;
    animation-fill-mode: forwards;
    .landing-page-header  {
      will-change: transform;
      top: 0px;
      opacity: 1;
      animation: remove-up-from-top 2s ease 0s;
      animation-fill-mode: forwards;
    }
    .landing-page-text {
      will-change: transform;
      top: 80px;
      opacity: 1;
      animation: remove-up-from-top 1.5s ease 0.5s;
      animation-fill-mode: forwards;
    }
    .landing-page-squares {
      will-change: transform;
      bottom: 140px;
      opacity: 1;
      animation: remove-up-from-bottom 1.5s ease 1s;
      animation-fill-mode: forwards;
    }
    .landing-page-scroll-wrapper {
      will-change: transform;
      bottom: 30px;
      opacity: 1;
      animation: fade-out 0.5s ease 0s;
      animation-fill-mode: forwards;
    }
  }
}


@media only screen and (max-width: 639px) {

  .landing-page {

    &-inner {
      width: 80%;
      max-width: 480px;
      height: 550px;
    }
  }

}

@media only screen and (max-width: 480px) {

  .landing-page {
    &-inner {
      width: 80%;
      max-width: 480px;
      height: 520px;
    }
    &-header {
      animation-name: header-up-responsive;
    }
    &-text {
      font: 18px/28px $klavikaLight;
    }
    &-squares {
      bottom: 160px;
    }
    &-scroll-wrapper {
      animation-name: scroll-wrapper-up-responsive;
    }
  }

}

@media only screen and (max-width: 360px) {
  .landing-page {
    &-squares {
      bottom: 120px;
    }
  }
}


@-webkit-keyframes square-up {
  from {
    top: 200px;
    opacity: 0;
  }
  to {
    top: 0px;
    opacity: 1;
  }
}
@-webkit-keyframes header-up {
  from {
    top: 150px;
    opacity: 0;
  }
  to {
    top: 0px;
    opacity: 1;
  }
}
@-webkit-keyframes paragraph-up {
  from {
    top: 250px;
    opacity: 0;
  }
  to {
    top: 80px;
    opacity: 1;
  }
}
@-webkit-keyframes scroll-wrapper-up {
  from {
    bottom: -60px;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  from {opacity: 0;}
  to {opacity: 1;}
}
@-webkit-keyframes fade-out {
  from {opacity: 1;}
  to {opacity: 0;}
}
@-webkit-keyframes up-down {
  0% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}
@-webkit-keyframes remove-up-from-top {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    top: -300px;
  }
}
@-webkit-keyframes remove-up-from-bottom {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    bottom: 500px;
  }
}

// Responsive animations
@-webkit-keyframes header-up-responsive {
  from {
    top: 80px;
    opacity: 0;
  }
  to {
    top: 40px;
    opacity: 1;
  }
}
@-webkit-keyframes scroll-wrapper-up-responsive {
  from {
    bottom: -30px;
    opacity: 0;
  }
  to {
    bottom: 40px;
    opacity: 1;
  }
}