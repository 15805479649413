$industry-box-size: 233px;
$industry-box-height: 183px;
$industry-box-size-mobile: 183px;
$industry-box-height-mobile: 183px;

.industry-boxes {
  background-color: $grey-lighter;
  .industry-boxes-wrapper {

  }

  .industry-boxes-inner {
    padding: 70px 0 160px 0;
    max-width: $desktop-width;
    margin: 0 auto;
  }
  .industry-boxes-boxes {
    display: grid;
    //grid-template-columns: repeat(auto-fit, minmax(100px, $industry-box-size));
    grid-template-columns: repeat(3, $industry-box-size);
    grid-gap: 20px;
    justify-content: center;
  }
  .industry-boxes-box {
      width: $industry-box-size;
      height: $industry-box-height;
      display: flex;

      &-layer {
        position: relative;
        align-self: center;
        margin: auto;
      }
      &-icon {
          text-align: center;
        img {
          width: 65px;
          height: 65px;
        }
      }
      &-text {
        color: $grey;
        text-align: center;
        margin: 14px 0;
        font: 18px $klavikaBold;
      }
      &-divider {
        position: absolute;
        bottom: -2px;
        left: 50%;
        transform: translateX(-50%);
        transition: bottom 0.2s;
        height: 2px;
        width: 40px;
        background-color: $grey;
      }
  }

  .industry-boxes-link {

    &:hover {
      text-decoration: none;
      .industry-boxes-box-divider {
        bottom: -12px;
        transition: bottom 0.2s;
      }
    }
  }

  .industry-boxes-smalltitle {
    text-align: center;
    font: 18px $klavikaBold;
    color: $grey-dark;
  }
  .industry-boxes-headline {
    text-align: center;
    font: 28px $klavikaLight;
    color: $grey-dark;
    margin-top: 5px;
    margin-bottom: 40px;
  }
}


@media only screen and (max-width: $mobile) {
  .industry-boxes {

    .industry-boxes-boxes {
      grid-template-columns: $industry-box-size-mobile $industry-box-size-mobile;
    }
    .industry-boxes-box {
      height: $industry-box-size-mobile;
      width: $industry-box-size-mobile;
    }
  }
}

@media only screen and (max-width: $smallDevices) {
  .industry-boxes {

    .industry-boxes-boxes {
      grid-template-columns: 1fr 1fr;
    }

    .industry-boxes-box {
      width: auto;
    }
  }
}

.FooterBiotech {
  width: 65px;
  height: 65px;
}