@include imgBoxRight($class:"team-detail", $colors: $purple);

.team-detail {

  .team-detail-wrapper {
    padding: 20px 0;
  }

  .team-detail-inner {
    max-width: $max-width;
    margin: 0 auto;
  }

  .team-detail-breadcrumb-holder {
    position: absolute;
    left: 470px;
  }
  .team-detail-back {
    max-width: 1280px;
    margin: 10px auto 20px auto;
    font: 12px $klavikaBold;
    color: $grey-light;
    text-transform: uppercase;
    a {
      background: sassvg('BackArrowGrey', $grey-light) left center no-repeat;
      padding-left: 35px;
    }
  }

  .team-detail-info {
    background-color: $grey-lighter;
    z-index: 1;
    &-container {
      &-inner {
        width: 100%;
      }
      &-header {
        float: left;
      }
    }
    &-holder {
      float: left;
      width: 60%;
      &-right {
        float: right;
        width:40%;
      }
    }
    &-title {
      font-size: 14px;
    }
    &-divider {
      height: 4px;
      background-color: $grey-dark;
      margin: 15px 0 10px 0;
    }
    &-kontakte {
      color: $grey-dark;
      font: 14px/17px $klavikaBold;
      padding: 5px 0;
    }
    &-street {
      @include contactTxt();
      @include contactPin();
    }
    &-street2 {
      @include contactTxt();
    }
    &-city {
      @include contactTxt();
    }
    &-phone {
      @include contactTxt();
      @include contactPhone();
    }
    &-fax {
      @include contactTxt();
      @include contactFax();
    }
    &-email {
      @include contactTxt();
      @include contactEmail();
    }
    &-contact-link {
      @include contactTxt();
      @include contactPDF();
    }
    &-expertise {
      font: 14px/17px $klavikaLight;
      padding: 5px 0 5px 0px;
      p {
        // loads from RTE, so remove margin
        margin: 0;
      }
    }
    &-link {
      text-transform: uppercase;
      margin-right: 40px;
      &.more-information {
        display: none;
      }
    }
  }

  select {
    background: sassvg('DropdownArrowGrey', $grey-dark) no-repeat center right;
  }

  .team-detail-cv {
    background: linear-gradient(90deg, $grey-lighter 50%, #fff 50%);

    &-box {
      margin-bottom: 30px;
    }

    &-info {
      margin: 5px 0;
      font: 14px/20px $klavikaLight;
    }
    &-text {
      background: #fff;
    }
    &-divider {
      height: 4px;
      background-color: $grey-dark;
      margin: 30px 0px 25px 0;
    }
    &-title {
      color: $grey;
      font: 14px $klavikaBold;
      margin-bottom: 0;
      margin-top: 0;
    }
    &-h3 {
      color: $grey;
      font-family: "Klavika Basic";
      font: 18px $klavikaLight;
      margin: 0;
    }
    &-intro {
      color: $grey;
      font: 18px/28px $klavikaLight;
      margin-bottom: 60px;
    }
  }
  .team-detail-cv-inner {
    display: grid;
    max-width: 1280px;
    margin: 0 auto;
    grid-template-columns: 300px 1fr;
    margin-top: 80px;
  }
  .team-detail-cv-info {
    background-color: $grey-lighter;
    padding: 35px 30px 30px 0;
  }
  .team-detail-cv-text {
    padding: 0 0 0 20px;
  }


}

@media only screen and (max-width: $responsive) {
  .team-detail {

    .team-detail-wrapper {
      padding: 50px 0 0px 0;
    }
    .team-detail-info {
      height: auto;
      background-color: $grey-lighter;
      &-holder {
        float: none;
        width: 100%;
        &-right {
          width: 100%;
          margin-left: 0;
          margin-top: 25px;
          margin-bottom: 15px;
        }
      }
      &-link {

      }
    }

    .team-detail-breadcrumb-holder {
      display: none;
    }
    .team-detail-back {
      max-width: 460px;
    }

    .team-detail-cv {
      background: none;
    }
    .team-detail-cv-inner {
      grid-template-columns: 1fr;
      max-width: unset;

    }
    .team-detail-cv-info {
      padding: 0;
      &-wrapper {
        max-width: 960px;
        padding: 30px 15px 20px 15px;
        margin: 0 auto;
      }
    }
    .team-detail-cv-text {
      padding: 50px 15px 0 15px;
      max-width: 960px;
      margin: 0 auto;
    }
  }
}

@media only screen and (max-width: 480px) {
  .team-detail {

    .team-detail-wrapper {
      //margin: 80px auto;
    }

    .team-detail-inner {
      max-width: $max-width;
      margin: 0 auto;
    }
    .team-detail-info {
      width: 93%;
      &-holder {
        float: none;
        &-right {
          margin-left: 0px;
        }
      }
    }

  }
}

.team-detail-logos-box {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  align-items: center;
  align-content: center;
}

.team-detail-images {
  overflow: hidden;
  width: 460px;
  height: 460px;

  .slick-arrow {
    border: none;
    text-indent: -1000px;
    background-size: 70%;
    top: unset;
    bottom: 10px;
    transform: unset;
    margin: 0;
    padding: 0;
    height: 42px;
    width: 30px;
  }
  .slick-prev {
    background-position: left;
  }
  .slick-next {
    background-position: right;
  }
  &.slick-dotted.slick-slider {
    margin-bottom: 0;
  }
}