.img-full {

  .img-full-wrapper {

  }

  .img-full-inner {
    margin: auto;
    &--grid {
      max-width: $max-width;
      margin: 0 auto;
    }
  }

}