.news-block {
  background-color: $grey-lighter;
  .news-block-wrapper {

  }

  .news-block-inner {
    max-width: $max-width;
    margin: auto;
  }

  .news-block-boxes {
    //max-width: $max-width;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 300px 300px 300px;
    justify-content: center;
  }

  .news-block-title {
    font: 18px $klavikaBold;
    color: $grey-dark;
    text-align: center;
    margin-bottom: 10px;
  }

  .news-block-text {
    font: 28px/38px $klavikaLight;
    color: $grey-dark;
    text-align: center;
    margin-bottom: 40px;
  }

  .news-block-box {
    background-color: #ffffff;
    &-content {
      padding: 20px;
    }
    &-img {
      line-height: 0;
      border-bottom: 4px solid $brown;
    }
    &-title {
      font: 14px $klavikaBold;
      color: $grey-dark;
      margin: 0;
    }
    &-text {
      font: 18px/28px $klavikaLight;
      color: $grey-light;
      margin: 10px 0;
    }
    &-link {
      opacity: 0;
      font: 12px $klavikaBold;
      color: $grey-dark;
      text-transform: uppercase;
    }
    &:hover {
      .news-block-box-link {
        opacity: 1;
      }
    }
  }

  .slick-arrow {
    top: unset;
    bottom: -40px;
    width: 28px;
    height: 26px;
  }
  .slick-next {
    right: 50%;
    transform: translateX(43px);
    background: sassvg("NextRegular", $grey-light) no-repeat center;
    &:hover {
      background: sassvg("NextRegular", $grey-dark) no-repeat center;
    }
  }
  .slick-prev {
    right: 50%;
    transform: translateX(-9px);
    left: unset;
    background: sassvg("NextHover", $grey-light) no-repeat center;
    &:hover {
      background: sassvg("NextHover", $grey-dark) no-repeat center;
    }
  }
}

@media only screen and (max-width: 1023px) {
  .news-block {

    .news-block-boxes {
      grid-template-columns: 300px;
    }

  }
}
