.news-grid {

  .news-grid-wrapper {
    margin: 0 auto;
    background-color: $grey-lighter;
    margin-top: 30px;
    padding: 50px 0;
  }
  .news-grid-inner {
    max-width: $max-width;
    margin: 0 auto;
  }

  .news-grid-search-holder {
    //display: grid;
    //grid-template-columns: 1fr 180px;
  }
  .header-search {
    display: block;
  }
  .news-grid-item {
    display: grid;
    grid-template-columns: 160px 160px 160px 1fr;
    grid-gap: 20px;
    &-select {
      .header-search-form-input {
        height: 27px;
        width: calc(100% - 20px);
        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: $grey-light;
        }
      }
      .btn-search {
        height: 30px;
      }
    }
  }

  .news-grid-order-holder {
    display: grid;
    grid-template-columns: 1fr 150px;
    margin-top: 38px;
  }
  .news-grid-sort {
    justify-self: end;
    display: flex;
    align-items: center;
    &-label {
      font: 14px $klavikaLight;
      color: $grey-light;
    }
    &-box {
      display: inline-block;
      margin-left: 10px;
      width: 15px;
      height: 14px;
      background: sassvg('ViewToggleGridDarkGrey', $grey-dark) no-repeat center left;
      background-repeat: no-repeat;
      cursor: pointer;
      &.inactive {
        background: sassvg('ViewToggleGridDarkGrey', $grey-light) no-repeat center left;
      }
    }
    &-list {
      display: inline-block;
      margin-left: 10px;
      width: 15px;
      height: 14px;
      background: sassvg('ViewToggleLinsesGrey', $grey-dark) no-repeat center left;

      background-repeat: no-repeat;
      cursor: pointer;
      &.inactive {
        background: sassvg('ViewToggleLinsesGrey', $grey-light) no-repeat center left;
      }
    }
  }

  .news-grid-single-holder {
    margin-top: 40px;
    display: none;
    grid-template-columns: 300px 300px 300px 300px;
    grid-gap: 27px;
    &.sort-grid {
      .news-grid-single-item {
        &.inactive {
          display: none;
        }
        &.active {
          display: block;
        }
      }
    }
    &.sort-list {
      .news-grid-single-item {
        &.inactive {
          opacity: 0.2;
        }
      }
    }
    & > div:nth-child(odd) {
      background-color: $grey-header;
    }
  }
  .news-grid-single-timer {
    padding: 20px;
    color: $grey-dark;
    font: 28px $klavikaLight;
    transition: opacity 0.4s;
  }
  .news-grid-single-item {
    position: relative;
    height: 300px;
    background-color: #ffffff;
    filter: grayscale(1);
    /****************** overlay *****************/
    &.display-grid:hover {
      .news-grid-single-item-overlay {
        transition: height 0.5s;
        height: 100%;
        background-color: transparent;
      }
      .news-grid-single-timer {
        opacity: 0;
        transition: opacity 0.2s;
      }
    }
    &-overlay {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      overflow: hidden;
      width: 100%;
      height: 135px;
      transition: height .5s ease;
      padding: 10px 20px;
      background-color: transparent;
    }
    /******************* END overlay END ***************/

    &-position {
      display: block;
      font: 14px $klavikaBold;
      color: $grey-dark;
      margin: 15px 0px 5px 0;
      //padding-left: 25px;
      img {
        width: 15px;
        height: 15px;
        padding-right: 5px;
        vertical-align: top;
      }
      .category-name {
        padding-right: 10px;
        &:last-child {
          padding-right: 0px;
        }
      }
    }
    &-name {
      display: block;
      font: 18px $klavikaLight;
      color: $grey-dark;
      margin: 10px 0;
      height: 42px;
      //word-break: break-word;
      &-list {
        font: 18px $klavikaLight;
        color: $grey-dark;
        margin: 10px 0;
        &:hover {
          text-shadow: 0px 0px 1px rgba(49, 49, 49, 1);
        }
      }
    }
    &-divider {
      display: block;
      height: 4px;
      width: 60px;
      background-color: $grey-dark;
      margin: 45px 0 25px 0;
    }
    &-text {
      font: 14px/24px $klavikaLight;
      color: $grey-dark;
      margin: 0;
    }
    &-detail {
      a {
        display: inline-block;
        text-align: left;
        margin-top: 20px;
        padding: 0;
        font-family: $klavikaBold;
        color: $grey-dark;
        font-size: 12px;
        line-height: 15px;
        text-transform: uppercase;
      }
    }
  }
  .news-grid-single-letter-headline {
    display: none;
  }
  .news-grid-acc-header {
    img {
      width: 15px;
      height: 15px;
      padding-right: 5px;
      vertical-align: top;
    }
    .category-name {
      padding-right: 10px;
      &:last-child {
        padding-right: 0px;
      }
    }
  }
  .news-grid-acc-trigger {
    display: none;
    cursor: pointer;
    &:after {
      content: "";
      display: block;
      position: absolute;
      right: 40px;
      top: 28px;
      background: sassvg('TabOpenGrey', $grey-dark) center center no-repeat;
      width: 17px;
      height: 17px;
      cursor: pointer;
    }
    &.active {
      &:after {
        background: sassvg('TabCloseGrey', $grey-dark) center center no-repeat;
      }
    }
  }

  /********** display as list ***********/
  .news-grid-single-holder.sort-list {
    display: block !important;
    .news-grid-single-timer {
      display: none;
    }
    .team-detail-image {
      display: none;
    }
    .news-grid-single-item {
      background: none !important;
      height: auto;
      /****************** overlay *****************/
      &:hover .news-grid-single-item-overlay {
        //display: block;
      }
      &-overlay {
        position: relative;
        height: auto;
        width: unset;
        display: none;
        background-color: unset !important;
        padding: 0;
        top: unset;
        left: unset;
        transition: none;
        &-inner {
          height: auto;
          margin: 15px;
          background-color: transparent;
          padding: 10px;
        }
        &:after {
          display: none;
        }
      }
      &-text {
        font: 18px/28px $klavikaLight;
      }
      &-position {
        display: none;
      }
      &-name {
        display: none;
      }
      &-divider {
        display: none;
      }
      /******************* END overlay END ***************/
      .news-grid-acc-trigger {
        display: block;
        padding: 10px 60px 10px 10px;
      }
      .news-grid-acc {
        &-text {
          position: relative;
          color: $grey-dark;
          font: 18px $klavikaLight;
          margin: 7px 0px 3px 0px;
        }
        &-header {
          margin: 3px 0px 7px 0px;
          position: relative;
          cursor: pointer;
          -webkit-tap-highlight-color: transparent;
          color: $grey-dark;
          font: 14px $klavikaBold;
        }
      }
    }
    & > div:nth-child(odd) {
      background-color: $grey-header !important;
    }
    & > div:nth-child(even) {
      background-color: $white !important;
    }
  }

}


@media only screen and (max-width: 1024px) {
  .news-grid {
    .news-grid-single-holder {
      grid-template-columns: 300px 300px;
      justify-content: center;
    }
  }
}


@media only screen and (max-width: 1024px) {
  .news-grid {
    .news-grid-inner {
      padding: 0 10px;
    }
    .news-grid-search-holder {
      grid-template-columns: 1fr;
      grid-row-gap: 30px;
      .header-search {
        min-height: unset;
      }
    }
    .news-grid-item {
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 20px;
      &-select {
        margin-left: unset;

        &:last-child {
          margin-right: 0;
        }
        .header-search-form-input {
          width: calc(100% - 20px);
          //width: 100%;
          max-width: none;
        }
      }
    }
    .news-grid-single-timer {
      display: none;
    }
    .news-grid-single-item {
      &-overlay {
        height: 100%;
      }
    }
    .team-grid-sword-wrapper {
      display: flex;
    }
    .select2.select2-container {
      width: 100%;
      max-width: unset;
    }
    select {
      width: 100%;
    }
    .header-search-form-input {
      width: 93%;
      max-width: 310px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .news-grid {
    .news-grid-single-holder {
      grid-template-columns: 300px;
    }
    .header-search-form .btn-search {
      right: -7px;
    }
  }
}

@media only screen and (max-width: 640px){
  /********** display list ***********/
  .news-grid {


    .news-grid-item {
      justify-self: unset;
      &-select {
        //width: 45%;
        float: none;
        margin-right: 0;
      }
      select {
        width: 100%;
      }

    }

    /********** display as list ***********/
    .news-grid-single-holder.sort-list {
      .news-grid-single-item {
        &-overlay {
          &-inner {
            margin: 0 15px 15px 15px;
            padding: 0px 10px 10px 10px;
          }
        }
      }
    }



  }


}

@media only screen and (max-width: 480px) {
  .news-grid {

    .news-grid-wrapper {
      margin: 80px auto;
    }

    .news-grid-inner {
      max-width: $max-width;
      margin: 0 auto;
    }
    .news-grid-item {
      &-select {
        float: none;
        margin-right: 0;
      }
      select {
        width: 100%;
      }
    }
    .select2.select2-container {
      min-width: 100px;
    }
    .news-grid-single-holder {
      grid-template-columns: 1fr;
      max-width: 300px;
      margin: 40px auto 0 auto;
    }
    .news-grid-sort {
      justify-self: end;
      display: flex;
      align-items: center;
      &-label {
        font: 14px $klavikaLight;
        color: $grey-light;
      }
      &-box {
        margin-left: 20px;
      }
      &-list {
        margin-left: 20px;
      }
    }
    .news-grid-search-holder {
      padding: 0 5px;
    }
  }
}

@media only screen and (max-width: 360px) {
  .news-grid {

    .news-grid-sort {
      &-box {
        margin-left: 7px;
      }
      &-list {
        margin-left: 7px;
      }
    }
  }
}

