.header-news-detail {

  .header-news-detail-wrapper {
    padding-bottom: 80px;
  }
  .header-news-detail-overlay {
    display: flex;
    flex-flow: column;
  }
  .header-news-detail-inner {
    height: 200px;
    width: 100%;
    margin: auto;
    background-color: $grey-header;
  }
  .header-news-detail-position {
    max-width: 1280px;
    margin: auto;
    width: 100%;
  }
  .header-news-detail-box {
    position: relative;
    width: 460px;
    padding: 30px;
    background-color: $brown;
    margin-top: -60px;
    //@include squareTop($color: $yellow);
    .header-news-detail-box-title {
      padding-left: 34px;
      margin: 0;
      color: #ffffff;
      font: 18px $klavikaBold;
      background: sassvg('CalendarWhite', #ffffff) no-repeat center left;
      background-size: 24px;
    }
    .header-news-detail-box-text {
      color: #ffffff;
      font: 28px $klavikaLight;
      margin: 10px 0;
    }
    .header-news-detail-box-line {
      height: 2px;
      width: 60px;
      background-color: #FFFFFF;
    }

  }
  .header-news-detail-author {
    max-width: $max-width;
    margin: auto;
    margin-top: 40px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font: 12px $klavikaBold;
    color: $grey;
    img {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }
    span {
      font: 12px $klavikaLight;
      text-transform: initial;
    }
  }
  .team-detail-back {
    background: sassvg('BackArrowGrey', $grey-light) left center no-repeat;
    padding-left: 35px;
    max-width: 1280px;
    margin: 10px auto 20px auto;
    font: 12px $klavikaBold;
    color: $grey-light;
    text-transform: uppercase;
    margin-top: 150px;
  }
}

@media only screen and (max-width: $responsive) {
  .header-news-detail {

    .header-news-detail-wrapper {
      padding-bottom: 90px;
    }
    .header-news-detail-box {
      max-width: 600px;
      //@include squareTop($color: $green);
    }

  }
}

@media only screen and (max-width: 768px) {
  .header-news-detail {

    .header-news-detail-position {
      max-width: 1280px;
      margin: auto;
      width: 100%;
    }
    .header-news-detail-box {
      margin-right: 10px;
      margin-left: 10px;
      width: auto;
      max-width: unset;
    }
    .team-detail-back {
      margin-top: 50px;
      margin-right: 10px;
      margin-left: 10px;
    }
    .header-news-detail-author {
      margin-right: 10px;
      margin-left: 10px;
    }
  }

}