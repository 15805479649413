/******** COLOR BOX *******/
#cboxOverlay {
  background-color: $white;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  overflow: visible;
}
#colorbox {
  z-index: 3;
  left: 0 !important;
  //width: 100% !important;
}
#cboxNext {
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  width: 33px;
  height: 66px;
  border: none;
  cursor: pointer;
  background: url("../Icons/ArrowSoloLightgrey.png") no-repeat center;
  &:hover {
    background: url("../Icons/ArrowSoloLight-black.png") no-repeat center;
  }
}
#cboxWrapper {
  margin: auto;
}
#cboxPrevious {
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translateY(-50%);
  width: 33px;
  height: 66px;
  border: none;
  cursor: pointer;
  background: url("../Icons/Back-ArrowSoloLightgrey.png") no-repeat center;
  &:hover {
    background: url("../Icons/Back-ArrowSoloLight-black.png") no-repeat center;
  }
}
#cboxClose {
  position: fixed;
  left: 100px;
  top: 50px;
  transition: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.light-box-back {
  background: sassvg('BackArrowGrey', $grey-light) left center no-repeat;
  padding-left: 35px;
  font: 12px $klavikaBold;
  color: $grey-light;
  text-transform: uppercase;
}
.light-box-logo {
  position: fixed;
  right: 100px;
  top: 50px;
  .MB-LogoSmall {
    display: block;
  }
}
#cboxTitle {
  float: none !important;
  margin-top: 10px;
  text-align: center;
  color: $grey-light;
  font-family: $klavika;
  font-size: 12px;
}
.cboxPhoto {
  //width: 100% !important;
  line-height: 0;
}
#cboxLoadedContent {
  //height: auto !important;
  text-align: center;
  line-height: 0;
}
@media only screen and (max-width: 768px) {
  #cboxClose {
    top: 30px;
    left: 20px;
  }
  .light-box-logo {
    display: none;
  }
}
