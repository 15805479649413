.footer {

  .footer-wrapper {
    background-color: $grey-lighter;
    margin-top: 30px;
  }

  .footer-inner {
    max-width: $max-width;
    height: 100px;
    margin: 0 auto;

    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 20px;
    align-content: center;
  }

  .footer-nav {
    align-self: center;

    ul {
      display: flex;
      margin: auto;

      li {
        margin: 0 10px;
        &:first-child {
          margin-left: 0;
        }
        position: relative;
        font: 14px $klavikaBold;

        a {
          font-size: 14px;
          font: 14px $klavikaBold;
        }

        &:after {
          display: block;
          content: '';
          position: absolute;
          right: -10px;
          top: 50%;
          transform: translateY(-50%);
          height: 16px;
          width: 1px;
          background-color: $grey-light;
        }

        &:last-child {
          &:after {
            display: none;
          }
        }
      }
    }
  }

  .footer-social {
    text-align: right;
    display: flex;
    justify-content: flex-end;
    & > div {
      display: inline-block;
      margin: auto 15px;
      svg {
        transition: opacity 300ms;
        &:hover {
          opacity: 0.7;
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}


@media only screen and (max-width: 1023px) {
  .footer {

    .footer-wrapper {

    }

    .footer-inner {
      grid-template-columns: 1fr;
      grid-gap: 70px;
      height: auto;
      padding: 60px 0;
    }

    .footer-nav {
      nav {
        display: flex;
      }

      ul {
        flex-direction: column;
        grid-gap: 30px;

        li {
          text-align: center;
          margin-bottom: 40px;
          &:last-child {
            margin-bottom: 0;
          }
          a {

          }

          &:after {
            display: none;
          }
        }
      }
    }

    .footer-social {
      text-align: center;
      display: block;
      justify-content: unset;
      & > div {
        margin: 30px 10px;
        display: block;

        &:last-child {
          margin-bottom: 0;
          margin-right: 10px;
        }
      }
    }
  }
}