.header-container-m {
  display: none;
}

.mob-menu-trigger-label, .mob-menu-trigger {
  display: none;
}
.header-search {
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #444;
  }
}

.mobile-header-nav-link-level-0 {
  margin: auto;
  &:hover {
    text-decoration: none;
  }
}

.mobile-header-nav {
  .menu-hamburger-item-level-0 {
    position: relative;
    padding: 0;
    margin: 0;
    min-height: 80px;

  }
  .menu-hamburger-link-level-0 {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: $white;
    height: 80px !important;
    &:hover {
      font-family: $klavikaBold;
    }
  }
  .header-search-form-input {
    border-bottom: 1px solid $white;
    color: $white;
  }
  .header-search-form {
    text-align: center;
    margin-top: 30px;
    .field {
      display: inline-block;
      position: relative;
    }
    .SearchGrey {
      filter: invert(0%) sepia(21%) saturate(1368%) hue-rotate(306deg) brightness(274%) contrast(97%);
    }
  }
  .language-holder a {
    color: $white;
  }
}

@media screen and (min-width: $responsive) {
  .mob-menu-trigger-search,
  .mob-menu-trigger-language {
    display: none;
  }
}

@media only screen and (max-width: $responsive) {
  .header-nav {
    display: none;
  }
  .header-nav-mobile {
    position: fixed;
    top: 80px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $blue;

    a {
      color: #ffffff;
      font-size: 18px;
    }

    span {
      color: #ffffff;
      font-size: 18px;
    }
  }

  .menu-trigger-label, .menu-trigger {
    display: none;
  }

  .header-container {
    display: block !important;
    //grid-template-columns: 1fr 1fr;
    max-width: 1024px;
    min-height: 80px !important;
    &:after {
      content: '';
      height: 0;
      display: block;
      clear: both;
    }
  }

  // MAIN MOBILE NAVIGATION SETTINGS
  // Change MOBILE navigation here
  // Do not change desktop navigation settings here!
  // All items here must be wrapped by .header-container-m !
  //.header-container-m,
  .mob-menu-trigger,
  .mob-menu-trigger-label {
    display: block;
  }

  .mob-menu-trigger-search {
    position: absolute;
    width: 30px;
    height: 35px;
    cursor: pointer;
    z-index: 5;
    top: 50%;
    left: 90px;
    transform: translateY(-50%);

    .btn-open-search {
      display: block;
    }
  }

  .mob-menu-trigger-language {
    position: absolute;
    z-index: 5;
    top: 50%;
    left: 60px;
    transform: translateY(-50%);
    cursor: pointer;
  }

  .header-container-m {
    position: fixed;
    top: 80px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    padding: 0;
    overflow-y: auto;
    background-color: $blue;
    opacity: 0;
    transition: all 0.1s linear;

    .header-nav {
      width: 100%;
      // Activation on hover
      &-item-level-0 {
        .header-nav-list-level-1 {
          // Disables hover from desktop menu
          position: relative;
        }

        &:hover {
          .header-nav-list-level-1 {
            // Disables hover from desktop menu
            display: none;
            // All other handling on submenu mobile is done via JS
          }
        }
      }
    }

    .item-has-sub {
      & > a {
        position: relative;
      }

      .submenu-switch {
        position: absolute;
        right: 0;
        width: 10%;
        height: 64px;
        font-style: inherit;
        transition: transform 1s ease;
        background: sassvg('DropdownArrowGrey', $white) no-repeat center center;
        transform: rotate(-90deg);
      }
    }

    .header-nav-list-toggle,
    .header-nav-list-toggle-0,
    .header-nav-list-toggle-1 {
      position: absolute;
      right: 0;
      top: 0;
      width: 50px;
      height: 100%;

      &.open {
        transform: rotate(180deg);
      }
    }
    .header-search {
      ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $white !important;
      }
    }
  }

  @include hamburger($class: "mob-menu", $left: 17px, $el-to-show: ".header-container-m");

  // Add transition
  .mob-menu-trigger + label,
  .page-wrap,
  .header-container-m {
    transition: right 0.2s;
    //right: 2px;
  }

  .mobile-header-nav {
    overflow: hidden;

    .language-holder {
      min-height: $mobile-item-height;
      color: $white;
      display: flex;

      & div {
        margin: auto;
      }
      a:hover {
        text-shadow: 1px 0px 1px $white;
        text-decoration: none;
      }
    }
  }

  ul.mobile-header-nav-list-level-0 {
    margin: 0;
    padding: 0;

    li.mobile-header-nav-item-level-0 {
      position: relative;
      padding: 0;
      margin: 0;
      min-height: $mobile-item-height;

      &.current {
        font-family: $klavikaBold;
      }
      &:hover {
        .mobile-header-nav-label-level0 label {
          //font-weight: bold;
          font-family: $klavikaBold;
        }
      }

      label {
        color: #ffffff;
        font-size: 18px;
        font-weight: 300;
        //width: 100%;
        display: block;
        line-height: 40px;
        padding: 0 20px;
        position: relative;
        transition: all 0.2s ease-out 0s;
        margin: auto;
        cursor: pointer;
        a {
          color: #3068B4;
          display: block;
          width: 50%;
          text-decoration: none;

          &.menu-page.active {
            color: $grey;
          }
        }

      }

      .language-selected {
        color: $white;
        text-shadow: 1px 0px 0px $white;
      }

      input[type=checkbox] {
        position: absolute;
        cursor: pointer;
        width: 10%;
        right: 0;
        height: $mobile-item-height;
        z-index: 1;
        opacity: 0;

        &:checked + .mobile-header-nav-label {
          font-family: $klavikaBold;
          //color: #F49128;
          &:after {
            //background: sassvg('ic-minus', #F9913A) center center no-repeat;
            background-size: 14px 5px;
          }
        }

        &:checked ~ .mobile-header-nav-list-level-1 {
          background-color: $blue-dark;
          opacity: 1;
          visibility: visible;
          height: auto;
        }

        &:checked ~ .submenu-switch {
          transform: rotate(0deg);
        }
      }
    }

    ul.mobile-header-nav-list-level-1 {
      opacity: 0;
      visibility: hidden;
      height: 0;
      transition: all 1s ease-out 0s;

      li {

        //display: flex;
        //align-items: center;
        text-align: center;
        //justify-content: center;
        position: relative;

        &:hover {
          a {
            font-family: $klavikaBold;
          }
        }

        &.active {
          a {
            font-family: $klavikaBold;
            color: #ffffff;
          }

        }
      }

      a {
        display: block;
        color: #ffffff;
        height: $mobile-item-height;
        position: relative;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .mobile-header-nav-label {
    display: flex;
    min-height: $mobile-item-height;
    align-items: center;
    //&.active {
    //  font-family: $klavikaBold;
    //}
  }

  .mobile-header-nav-label b {
    color: #fff !important;
  }

  .header-search {
    //@media screen and (min-width: 768px) {
    //  min-height: $mobile-item-height;
    //}

    &-form {
      &-input {
        color: #ffffff;
        border-bottom: 1px solid #ffffff;

      }
    }

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #444;
      opacity: 1; /* Firefox */
      font-size: 14px;
    }
  }

  .header-logo {
    max-width: 201px;
    //margin-right: 20px !important;
    float: right;
    &-img {
      margin: auto 20px auto auto;
      line-height: 0;
    }
  }

}

@media screen and (min-width: $mobile) {
  .mob-menu-trigger-language {
    left: 100px;
  }

  .mob-menu-trigger-search {
    left: 170px;
  }
}

@media screen and (max-width: $smallDevices) {
  .mob-menu-trigger-language {
    left: 55px;
  }

  .mob-menu-trigger-search {
    left: 80px;
  }
}