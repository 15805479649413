.accordion-grid {

  h3 {
    margin:10px auto;
  }

  .accordion-grid-inner > h3 {
    margin-bottom: 40px;
  }

  @include bullet-list();

  .accordion-grid-wrapper {
    margin: 0 auto;
    //background-color: $grey-lighter;
    padding: 10px 0 50px 0;
    margin-top: 30px;
  }

  .accordion-grid-inner {
    max-width: $max-width;
    margin: 0 auto;
  }
  .accordion-grid-sort {
      &-box {
        display: inline-block;
        margin-left: 10px;
        width: 15px;
        height: 14px;
        background: sassvg('ViewToggleGridDarkGrey', $grey-dark) no-repeat center left;
        background-repeat: no-repeat;
        cursor: pointer;
        &.inactive {
          background: sassvg('ViewToggleGridDarkGrey', $grey-light) no-repeat center left;
        }
      }
    &-list {
      display: inline-block;
      margin-left: 10px;
      width: 15px;
      height: 14px;
      background: sassvg('ViewToggleLinsesGrey', $grey-dark) no-repeat center left;

      background-repeat: no-repeat;
      cursor: pointer;

      &.inactive {
        background: sassvg('ViewToggleLinsesGrey', $grey-light) no-repeat center left;
      }
    }
  }

  //
  .accordion-grid-single-holder {
    &.sort-list {
      .accordion-grid-single-item {
        &.inactive {
          opacity: 0.2;
        }
      }
    }
  }

  .accordion-grid-single-item {
    position: relative;
        height: auto;
      background-color: #ffffff;


    .accordion-grid-acc-trigger {
      display: none;

      &:before {
        content: "";
        display: block;
        position: absolute;
        left: 40px;
        top: 24px;
        background: sassvg('TabOpenGrey', $grey-dark) center center no-repeat;
        width: 17px;
        height: 17px;
        cursor: pointer;
      }

      &.active {
        &:before {
          background: sassvg('TabCloseGrey', $grey-dark) center center no-repeat;
        }
      }
    }
  }

  /********** display list ***********/
  .accordion-grid-single-holder.sort-list {
    display: block;

    .team-detail-image {
      display: none;
    }
    .accordion-grid-single-item {
      height: auto;
      /****************** overlay *****************/
      &:hover .accordion-grid-single-item-overlay {
        //display: block;
      }
      &-overlay {
        position: relative;
        height: auto;
        width: unset;
        display: none;
        background-color: unset !important;
        padding: 0;
        top: unset;
        left: unset;
        transition: none;
        &-inner {
          height: auto;
          margin: 0 15px 15px 15px;
          background-color: transparent;
          padding: 0 10px 10px 20px;
        }
        &:after {
          display: none;
        }
      }
      &-text {
        font: 18px/28px $klavikaLight;
      }
      &-position {
        display: none;
      }
      &-name {
        display: none;
      }
      &-divider {
        display: none;
      }
      /******************* END overlay END ***************/
      .accordion-grid-acc-trigger {
        display: block;
        padding: 10px 10px 10px 80px;
        cursor: pointer;
      }
      .accordion-grid-acc {
        &-text {
          position: relative;
          color: $grey-dark;
          font: 18px $klavikaLight;
          margin: 7px 0px 3px 0px;
        }
        &-header {
          margin: 3px 0px 7px 0px;
          position: relative;
          -webkit-tap-highlight-color: transparent;
          color: $grey-dark;
          font: 14px $klavikaBold;
        }
      }

    }

  }
}


@media only screen and (max-width: 1024px) {
  .accordion-grid {
    .accordion-grid-inner {
      padding: 0 10px;
    }
    .accordion-grid-single-holder {
      justify-content: center;
    }
    .accordion-grid-search-holder {
      grid-template-columns: 1fr;
      grid-row-gap: 30px;
      .header-search {
        min-height: unset;
      }
    }
    .accordion-grid-item {
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 20px;
      &-select {
        margin-left: unset;

        &:last-child {
          margin-right: 0;
        }
        .header-search-form-input {
          width: calc(100% - 20px);
          //width: 100%;
          max-width: none;
        }
      }
    }
    .accordion-grid-single-item {
      &-overlay {
        height: 100%;
      }
    }
    .team-grid-sword-wrapper {
      display: flex;
    }
    .select2.select2-container {
      width: 100%;
      max-width: unset;
    }
    select {
      width: 100%;
    }
    .header-search-form-input {
      width: 93%;
      max-width: 310px;
    }
    .accordion-grid-single-holder {
      grid-template-columns: 300px 300px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .accordion-grid {
    .accordion-grid-single-holder {
      grid-template-columns: 300px;
    }
    .header-search-form .btn-search {
      right: -7px;
    }
  }
}

@media only screen and (max-width: 640px){
  /********** display list ***********/
  .accordion-grid {
    .accordion-grid-item {
      justify-self: unset;
      //.header-search-form-input {
      //  width: calc(100% - 20px);
      //}
      &-select {
        //width: 45%;
        float: none;
        margin-right: 0;
      }
      select {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .accordion-grid {

    .accordion-grid-wrapper {
      margin: 80px auto;
    }

    .accordion-grid-inner {
      max-width: $max-width;
      margin: 0 auto;
    }
    .accordion-grid-item {
      &-select {
        float: none;
        margin-right: 0;
      }
      select {
        width: 100%;
      }
    }
    .select2.select2-container {
      min-width: 100px;
    }
    .accordion-grid-single-holder {
      grid-template-columns: 1fr;
    }
  }
}

.accordion-content,
.accordion-box-text {
  padding-left:45px;
  p:first-of-type {
    margin-top: 0;
  }
}
