body {
  margin: 0;
  padding: 0;
  font-size: 18px;
  line-height: 28px;
  font-family: $klavikaLight;
  color: $grey;
  font-weight: 300;
}
div {
  box-sizing: border-box;
}
h1, .h1 {
  color: #FFFFFF;
  font-size: 28px;
  font-weight: 300;
  line-height: 38px;
  //hyphens: auto;
  //word-break: break-word;
}

h2, .h2 {
  color: $grey-dark;
  font-size: 28px;
  font-weight: 300;
  letter-spacing: 0.2px;
  line-height: 38px;
  //hyphens: auto;
  //word-break: break-word;
}

h3, .h3 {
  color: $grey-dark;
  //hyphens: auto;
  //word-break: break-word;
}

h4, .h4 {
  //hyphens: auto;
  //word-break: break-word;
}

ul {
  padding: 0;
  list-style: none;
}

img {
  width: 100%;
  height: auto;
}

a {
  text-decoration: none;
  color: $blue;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

input {
  border: none;
  border-bottom: 1px solid $grey-light;
  border-radius: 0;
  height: 30px;
  font-size: 14px;
  color: $grey-light;
  background-color: transparent;
  font-family: $klavikaLight;
}

textarea {
  font-size: 14px;
  color: $grey-light;
  font-family: $klavikaLight;
}

select {
  visibility: hidden;
}

*:focus {
  outline: none;
}


.box-blue {
  background-color: $blue-transparent;

  .color-boxes-boxes & {
    background-color: $blue;
  }
}

.box-green {
  background-color: $green-transparent;

  .color-boxes-boxes & {
    background-color: $green;
  }
}

.box-red {
  background-color: $red-transparent;

  .color-boxes-boxes & {
    background-color: $red;
  }
}

.box-purple {
  background-color: $purple-transparent;

  .color-boxes-boxes & {
    background-color: $purple;
  }
}

.box-yellow {
  background-color: $yellow-transparent;

  .color-boxes-boxes & {
    background-color: $yellow;
  }
}

.box-brown {
  background-color: $brown-transparent;

  .color-boxes-boxes & {
    background-color: $brown;
  }
}

.box-grey {
  background-color: $grey-transparent-header;

  .color-boxes-boxes & {
    background-color: $grey-header;
  }
}

// Used for blog headers
.box-blue-gradient { background: linear-gradient(0deg, rgba(0,61,165,1) 40%, rgba(0,61,165,0.8) 100%)}
.box-green-gradient { background: linear-gradient(0deg, rgba(0,128,0,1) 40%, rgba(0,128,0,0.8) 100%)}
.box-red-gradient { background: linear-gradient(0deg, rgba(255,0,0,1) 40%, rgba(255,0,0,0.8) 100%)}
.box-purple-gradient { background: linear-gradient(0deg, rgba(89,7,118,1) 40%, rgba(89,7,118,0.8) 100%)}
.box-yellow-gradient { background: linear-gradient(0deg, rgba(255,181,0,1) 40%, rgba(255,181,0,0.8) 100%)}
.box-brown-gradient { background: linear-gradient(0deg, rgba(98,52,18,1) 40%, rgba(98,52,18,0.8) 100%)}
.box-grey-gradient { background: linear-gradient(0deg, rgba(223,227,230,1) 40%, rgba(223,227,230,0.8) 100%)}


.ce_video {

  &__holder {
    position:relative;
    //max-width: 375px;
    //height: 210px;
    margin: auto;
    padding-bottom: 56.25%;
    height: 0;
  }
  &__player {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    object-fit: cover;
    cursor: pointer;
  }

}
.cta-link {
    font: 18px $klavikaBold;
    color: $grey-dark;
    text-transform: uppercase;
}

.cta {
    font: 18px $klavikaBold;
    color: $white;
    background-color: $blue;
    padding: 10px 25px;
}

.cta-block {
    display: block;
    text-align: center;
    font: 18px $klavikaBold;
}

/********** SELECT2 ************/
.select2 {
  &.select2-container {
    min-width: 150px;
    max-width: 150px;
  }
  .select2-results__option {
    font: 14px $klavikaLight;
    line-height: 16px;
  }
  .select2-selection.select2-selection--single {
    outline: none;
  }
  &.select2-container--default .select2-selection--single {
    border: none;
    height: 30px;
    font: 14px $klavikaLight;
    color: $grey-light;
    background-color: transparent;
    padding-right: 20px;
    -webkit-appearance: none;
    border-radius: 0;
    border-bottom: 1px solid $grey-light;
  }
  .select2-selection__arrow {
    transition: transform 0.2s;
    background: sassvg('DropdownArrowGrey', $grey-light) no-repeat center right;
    b {
      display: none;
    }
  }
  &.select2-container--default.select2-container--open .select2-selection__arrow {
    transition: transform 0.2s;
    transform: rotateX(180deg);
  }
  &-search__field {
    display: none;
  }
  .select2-selection__rendered {
    padding-right: 8px !important;
    padding-left: 0!important;
  }
}
.select2-container {
  .select2-results__option {
    font: 14px $klavikaLight;
    line-height: 16px;
    color: $grey-dark;
  }
  .select2-dropdown {
    border: none;
    max-width: 300px;
    margin-left: -20px;
  }
  .select2-search--dropdown {
    padding: 0px;
  }
}

.select2-results__options {
  background-color: $grey-lighter;
}
.select2-container--default
.select2-results__option[aria-selected=true] {
  position: relative;
  font-family: $klavikaBold;
  //padding-left: 15px;
  background-color: unset !important;
  color: $grey-dark !important;
  //&:before {
  //  display: block;
  //  position: absolute;
  //  top: 12px;
  //  left: 6px;
  //  height: 2px;
  //  width: 5px;
  //  background-color: $grey-dark;
  //  content: '';
  //}
}
.select2-container--default
.select2-results__option--highlighted[aria-selected=false] {
  background-color: unset !important;
  color: $grey-dark !important;
  text-shadow: 0 0 1px $grey-dark;
}
.select2-container--default .select2-results>.select2-results__options {
  max-height: 310px !important;
}
.select2-container--default .select2-results>.select2-results__options {
  padding: 20px;
}
//.select2-container--default .select2-selection--single
 .select2-selection__rendered {
  color: $grey-light !important;
}
/****** end SELECT2 end ********/

.outer-margin {
  padding: 30px 0;
}

.border-top-blue {
  @include border-top;
}
.border-top-purle {
  @include border-top($purple);
}

.back-link {
  max-width: 1280px;
  margin: 10px auto 20px auto;
  &-link {
    background: sassvg('BackArrowGrey', $grey-light) left center no-repeat;
    padding-left: 35px;
    font: 12px $klavikaBold;
    color: $grey-light;
    text-transform: uppercase;
  }
}

.team-grid-sword-wrapper {
  display: inline-block;
  position: relative;
}

.display-grid {
  display: grid;
  display: -ms-grid;
}
.clearfix {
  clear: both;
}
.search-not-found {
  display: none;
  margin-top: 40px;
  color: $red;
}
.grey-background {
  background-color: $grey-lighter !important;
}
/********** ACORDION **********/
.accordion {
  &-container {
    max-width: 1280px;
    display: block;
    margin: 0 auto;
  }

  &-toggle-list {
    background: #fff;
    border-radius: 4px;
  }

  .faq-toggle-list-item {
    padding-right: 70px;
    border-top: 2px solid $grey-light;
    &:last-child {
      border-bottom: 2px solid $grey-light;
    }
  }

  .faq-toggle-trigger {
    margin: 0;
    padding: 20px 0px;
    color: $grey-light;
    font: 18px $klavikaLight;
    position: relative;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;

    &:after {
      content: "";
      display: block;
      position: absolute;
      right: -60px;
      top: 24px;
      background: sassvg('TabOpenGrey', $grey-light) center center no-repeat;
      width: 17px;
      height: 17px;
    }

    &.active {
      font-weight: bold;
      &:after {
        background: sassvg('TabCloseGrey', $grey-light) center center no-repeat;
      }
    }
  }

  .faq-toggle-description {
    p {
      color: $grey-light;
      margin-bottom: 0px;
    }
    &-box {
      border-bottom: 1px dotted $grey-light;
      padding: 25px 0;
      &:last-child {
        border-bottom: none;
      }
      & > p:first-child {
        margin-top: 0;
      }
    }
  }
  .faq-toggle-date {
    color: $grey;
    font: 14px $klavikaBold;
  }

  .faq-toggle-link {
    margin: 10px 0px;
    width: auto;
    display: inline-block;
    text-transform: uppercase;
    color: $grey;
    font: 12px $klavikaBold;
    &:hover {
      text-decoration: underline;
    }

    &.faq-toggle--publication-link {
      margin: 10px 0;
    }
  }

  .faq-toggle--publication-name,
  .faq-toggle--publication-description {
    margin: 10px 0 10px;
  }

  .faq-toggle--publication-description p {
    margin-top: 19px;
  }
}


/*** SLICK SLIDER ****/

.slick-arrow{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 33px;
  height: 66px;
  z-index: 1;
  cursor: pointer;
  &.slick-disabled {
    opacity: 0.3;
  }
}
.slick-next {
  background: url("../Icons/ArrowSoloLightgrey.png") no-repeat center;
  right: 0;
  content: '';
  &:hover {
    background: url("../Icons/ArrowSoloLight-black.png") no-repeat center;
  }
}

.slick-prev {
  background: url("../Icons/Back-ArrowSoloLightgrey.png") no-repeat center;
  left: 0;
  &:hover {
    background: url("../Icons/Back-ArrowSoloLight-black.png") no-repeat center;
  }
}

/* SLICK DOTS */
.slick-dotted.slick-slider
{
  margin-bottom: 30px;
}

.slick-dots
{
  position: absolute;
  bottom: -25px;

  display: block;

  //width: 100%;
  padding: 0;
  margin: 0;

  list-style: none;

  text-align: center;
}
.slick-dots li
{
  position: relative;

  display: inline-block;

  width: 20px;
  height: 20px;
  //margin: 0 5px;
  padding: 0;

  cursor: pointer;
}
.slick-dots li button
{
  font-size: 0;
  line-height: 0;

  display: block;

  width: 20px;
  height: 20px;
  padding: 5px;

  cursor: pointer;

  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus
{
  outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before
{
  opacity: 1;
}

.slick-dots li.slick-active button:before
{
  opacity: .75;
  color: black;
}

/*** SVG ICONS ***/
.SearchGrey {
  width: 16px;
  height: 15px;
}
.MB-LogoBig {
  display: block;
  width: 275px;
  height: 50px;
}
.MB-LogoSmall {
  display: none;
  width: 192px;
  height: 35px;
}
.SocialNetworksXingRegular {
  width: 21px;
  height: 22px;
}
.SocialNetworksLinkedinRegular {
  width: 20px;
  height: 19px;
}
.SocialNetworksTwitterRegular {
  width: 20px;
  height: 19px;
}
.SocialNetworksKununuRegular {
  width: 63px;
  height: 15px;
}
.SocialNetworksInstagramRegular {
  width: 20px;
  height: 20px;
}
.DropdownArrowGrey {
  width: 17px;
  height: 9px;
}
.FaxDarkgrey{
  width: 15px;
  height: 15px;
}
.FooterBiotech,
.FooterChemicals,
.FooterContact,
.FooterEngineering,
.FooterIT,
.FooterLocations,
.FooterMaterials,
.FooterMedicals,
.FooterNewsletter,
.FooterPhysics {
  width: 65px;
  width: 65px;
}
.ArrowSoloLightgrey {
  width: 33px;
  height: 666px;
}
.PublicationScrollindicator {
  width: 30px;
  height: 45px;
}



@media only screen and (max-width: $responsive) {
  .MB-LogoBig {
    display: none;
  }
  .MB-LogoSmall {
    display: block;
  }
  .back-link {
    margin-left: 10px;
  }

  iframe[src="https://www.meissnerbolte.de/intro/"] {
    display: none;
  }
}

.frame-type-form_formframework {
  margin: $ce-margins;
  padding: 60px 0;
  background: $grey-lighter;
}

form {
  max-width: $max-width;
  margin: 0 auto;
  @media only screen and (max-width: $responsive) {
    padding: 0 20px;
  }

  .btn-primary {
    background-color: transparent;
    font: 12pt KlavikaBold;
    color: #313131;
    border: 1px solid #313131;
    padding: 12px 24px;
    height: unset;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.25s;

    &:hover {
      border-radius: 8px;
    }
  }
}

.form-group {
  margin-bottom: 50px;
  vertical-align: top;

  input {
    width: 100%;
    font-size: 20px;
    color: #222;
  }

  @media only screen and (min-width: $responsive) {
    width: 49%;
    display: inline-block;
    &:nth-of-type(odd) {
      padding-left: 20px;
    }
    &:nth-of-type(even) {
      padding-right: 20px;
    }
  }

  textarea {
    width: 96%;
    min-height: 150px;
    padding: 10px;

    font-size: 20px;
    color: #222;
  }

  input[type=checkbox] {
    width: auto;
    vertical-align: middle;
  }

  .inputs-list {
    margin-top: 20px;
  }
}

strong, b {
  font-family: $klavikaBold;
}