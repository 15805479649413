@include imgBoxRight($class:"contact-box", $colors: $red);

.contact-box {

  .contact-box-wrapper {
    margin: 30px auto;
    padding: 80px 0;
  }

  .contact-box-inner {
    max-width: $max-width;
    margin: 0 auto;
  }

  .contact-box-image {

  }
  .contact-box-holder {

  }
  .contact-box-info {
    background-color: $grey-lighter;
    height: 342px;
    &-holder {
      float: left;
      width: 100%;
      max-width: 220px;
      margin-right: 40px;
      &-right {
      }
    }
    &-holder-right {
      margin-right: 0px;
    }
    &-divider {
      height: 4px;
      width: 70%;
      background-color: $grey-dark;
      margin: 30px 0 25px 0;
    }
    &-kontakte {
      color: $grey-dark;
      font: 14px/17px $klavikaBold;
      padding: 5px 0;
    }
    &-text {
      color: $grey-dark;
      font: 28px/38px $klavikaLight;
    }
    &-street {
      @include contactTxt();
      @include contactPin();
      background-position-y: 7px;
      padding-top: 2px;
      line-height: 25px;
      max-height: 108px;
      overflow: auto;
    }
    &-street2 {
      @include contactTxt();
    }
    &-city {
      @include contactTxt();
    }
    &-phone {
      @include contactTxt();
      @include contactPhone();
    }
    &-fax {
      @include contactTxt();
      @include contactFax();
    }
    &-email {
      @include contactTxt();
      @include contactEmail();
      margin-bottom: 10px;
    }
    &-link {
      text-transform: uppercase;
    }
    &-title {
      margin-bottom: 10px;
    }
    &-container {
      display: block;
      height: unset;
      justify-content: unset;
      &-inner {
        margin: 0;
      }
    }
  }

  .select2 {
    &.select2-container {
      min-width: 276px;
      max-width: 276px;
      border-bottom: 2px solid $grey-dark;
    }
    &.select2-container--default .select2-selection--single {
      border: none;
      height: 30px;
      font: 28px $klavikaLight;
      color: $grey-dark !important;
      background-color: transparent;
      padding-right: 20px;
      -webkit-appearance: none;
    }
    .select2-selection__arrow {
      background: sassvg('DropdownArrowGrey', $grey-dark) no-repeat center right;
      b {
        display: none;
      }
    }
    &.select2-container--default.select2-container--open .select2-selection__arrow {
      background: sassvg('DropdownArrowGrey', $grey-dark) no-repeat center right;
    }
    &-search__field {
      display: none;
    }
    .select2-selection__rendered {
      padding-right: 8px !important;
      color: $grey-dark !important;
    }
  }

  .location-info {
    display: none;
  }
  .location-info-1 {
    display: block;
  }
}

@media only screen and (max-width: $responsive) {
  .contact-box {

    .contact-box-wrapper {
      padding: 50px 0 80px 0;
    }

    .contact-box-info {
      height: auto;
      max-width: 440px;
      &-divider {
        width: 60%;
      }
      &-text {
        font-size: 24px;
      }
      .select2 {
        &.select2-container--default .select2-selection--single {
          font-size: 24px;
        }
      }
      &-holder {
        margin-right: 40px;
      }
      &-street {
        max-height: unset;
      }
    }
    .select2 {
      &.select2-container {
        min-width: 200px;
        max-width: 200px;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .contact-box {

    .contact-box-wrapper {
      margin: 80px auto;
    }

    .contact-box-inner {
      max-width: $max-width;
      margin: 0 auto;
    }

    .contact-box-info {
      padding: 30px 10px;
      &-holder {
        margin-right: 40px;
      }
      &-socials {
        padding-left: 10px;
      }
    }

    .select2 {
      &.select2-container {
        min-width: 170px;
        max-width: 170px;
      }
    }

  }
}

@media only screen and (max-width: 380px) {
  .contact-box {

    .contact-box-info {

      &-holder {
        float: none;
        margin-right: 0px;
      }
    }

    .select2 {
      &.select2-container {
        min-width: 140px;
        max-width: 140px;
      }
    }

  }
}