.podcast--two-cols {
  max-width: 1280px;
  margin: 0 auto;
  display: grid;
  grid-gap: 40px;
  padding: 30px 10px;

  @media (min-width: 1024px) {
    padding: 30px 0;
    grid-template-columns: 1fr 380px;
    grid-gap: 40px;
  }
}

.podcast--image {
  display: block;
  line-height: 1;
  width: 100%;
  height: auto;
}

.podcast--second-col {
  order: -1;

  @media (min-width: 1024px) {
    order: unset;
  }
}

.podcast--header {
  margin-top: 0;
}