.map-box2 {

  .map-box2-wrapper {
    position: relative;
  }

  .map-box2-inner {
    position: relative;
    margin: auto;
    height: 700px;
    background-color: aliceblue;
  }

  .map-box2-box {
    position: absolute;
    top: 100px;
    left: 10%;
    height: auto;
    width: 540px;
    background-color: $grey-lighter;
    border-top: 4px solid $red;
    padding: 30px;
    z-index: 1;
  }

  .map-box2-title {
    @include boxTitle();
    margin-bottom: 10px;
    margin-top: 0px;
  }
  .map-box2-text {
    @include boxText();
    margin: 10px 0;
  }
  .map-box2-link {
    @include boxLink();
  }

  &-holder {
    float: left;
    &-right {
      margin-left: 70px;
    }
  }
  &-divider {
    height: 4px;
    background-color: $grey-dark;
    margin: 30px 0 25px 0;
  }
  &-kontakte {
    color: $grey-dark;
    font: 14px/17px $klavikaBold;
    padding: 5px 0;
  }
  &-street {
    @include contactTxt();
    @include contactPin();
  }
  &-street2 {
    @include contactTxt();
  }
  &-city {
    @include contactTxt();
  }
  &-phone {
    @include contactTxt();
    @include contactPhone();
  }
  &-fax {
    @include contactTxt();
    @include contactFax();
  }
  &-email {
    @include contactTxt();
    @include contactEmail();
    margin-bottom: 10px;
  }
  &-link {

  }


  .accordion {
    margin-top: 50px;
    &-toggle-list {
      background-color: transparent;
    }
    .faq-toggle-description {
      &-box {
        padding: 5px 0 20px 0;
      }
      p {
        margin-top: 0;
        margin-left: 20px;
        color: $grey-dark;
      }
    }
  }


}

@media only screen and (max-width: 1023px) {
  .map-box2 {

    .map-box2-wrapper {

    }

    .map-box2-inner {
      padding: 0 10px;
    }


  }
}

@media only screen and (max-width: 768px) {
  .map-box2 {

    .map-box2-wrapper {
      display: flex;
      flex-flow: column;
    }

    .map-box2-inner {
      width: 100%;
    }
    .map-box2-box {
      position: relative;
      top: unset;
      bottom: unset;
      height: auto;
      max-width: 400px;
      width: 90%;
      margin: auto;
      margin-top: -80px;
      left: unset;
      transform: unset;
      z-index: 2;
    }
    .map-box2-layer {
      text-align: left;
      margin: 10px 30px;
    }
    .map-box2-title {
      margin-bottom: 10px;
      margin-top: 0px;
    }
    &-holder {
      float: none;
      &-right {
        margin-left: 0px;
      }
    }

  }
}
