.light-box {

  .light-box-wrapper {
    padding: 60px 0;
  }

  .light-box-inner {
    margin: auto;
  }

}