$color-box-size: 220px;
$color-box-size-tablet: 200px;
$color-box-size-mobile: 167px;
$color-boxes-width: 1280px;

.color-boxes {

  .color-boxes-wrapper {

  }

  .color-boxes-inner {
    padding: 120px 0;
  }

  .color-boxes-outer {
    display: grid;
    grid-template-columns: 240px 1fr;
    max-width: $color-boxes-width;
    margin: 0 auto;
  }

  .color-boxes-info {
    display: flex;
    align-items: center;
    &-layer {
      background-color: $grey-lighter;
      padding: 90px 30px;
      width: 100%;
    }
    &-title {
      font: 18px $klavikaBold;
      color: $grey-dark;
    }
    &-text {
      font: 28px $klavikaLight;
      color: $grey-dark;
      margin: 15px 0;
    }
    &-divider {
      height: 4px;
      width: 60px;
      background-color: $grey-dark;
      margin: 0;
    }
  }

  a {
    &:hover {
      text-decoration: none;
      .color-boxes-boxes-box {
        background: $white;
        transition: background 0.3s;
      }

      @each $class-name, $color in $box-colors {

        .color-boxes-boxes-box.#{$class-name} {

          border: 2px solid $color;
          .color-boxes-boxes-box-title {
            color: $color;
            transition: color 0.3s;
          }
          .color-boxes-boxes-box-arrow {
            transition: background 0.3s;
            background: sassvg('ArrowWhite', $color) no-repeat;
          }

        }

      }

    }
  }

  .color-boxes-boxes {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, $color-box-size));
    grid-gap: 20px;
    &-box {
      height: $color-box-size;
      border: 2px solid transparent;
      &-title {
        padding: 25px;
        position: relative;
        font: 28px $klavikaLight;
        color: #ffffff;
      }
      &-arrow {
        margin-left: 25px;
        width: 24px;
        height: 16px;
        background: sassvg('ArrowWhite', #fff) no-repeat;
      }
    }
  }

}


@media only screen and (max-width: $responsive) {
  .color-boxes {

    .color-boxes-outer {
      grid-template-columns: 1fr;
      max-width: 700px;
    }

    .color-boxes-info {

      &-layer {
        margin: auto;
        padding: 30px;
        width: auto;
        min-width: 400px;
        min-width: 500px;
      }
    }

    .color-boxes-boxes {
      grid-template-columns: 200px 200px 200px;
      max-width: 640px;
      margin: auto;
      &-box {
        height: $color-box-size-tablet;
        &-title {
          font-size: 24px;
          &:after {
            top: 60px;
          }
        }
      }
    }

  }
}

@media only screen and (max-width: $mobile) {

  .color-boxes {

    .color-boxes-outer {
      grid-template-columns: 1fr;
      justify-items: center;
    }

    .color-boxes-info {

      &-layer {
        padding: 30px 20px;
        min-width: 250px;
        max-width: 300px;
      }
      &-text {
        font-size: 24px;
      }
    }

    .color-boxes-boxes {
      grid-template-columns: $color-box-size-mobile $color-box-size-mobile;
      max-width: 450px;
      &-box {
        height: $color-box-size-mobile;
        &-title {
          font-size: 24px;
          &:after {
            top: 60px;
          }
        }
      }
    }

  }
}

@media only screen and (max-width: 480px) {

  .color-boxes {

    .color-boxes-boxes {
      &-box {
        &-title {
          padding-left: 15px;
        }
        &-arrow {
          margin-left: 15px;
        }
      }
    }

  }
}

@media only screen and (max-width: 350px) {
  .color-boxes {

    .color-boxes-boxes {
      grid-template-columns: $color-box-size-mobile;
    }

  }
}




/******** Color Boxes Large *********/
$color-box-large-size: 298px;
$color-box-large-size-mobile: 167px;
$color-boxes-large-large-width: 1280px;

.color-boxes-large {

  .color-boxes-large-wrapper {

  }

  .color-boxes-large-inner {
    margin: 0 auto;
    padding: 120px 0;
    max-width: $max-width;
  }
  a {
    &:hover {
      text-decoration: none;
      .color-boxes-large-boxes-box {
        background: $white;
        transition: background 0.3s;
      }

      @each $class-name, $color in $box-colors {

        .color-boxes-large-boxes-box.#{$class-name} {

          border: 2px solid $color;
          .color-boxes-large-boxes-box-title {
            color: $color;
            transition: color 0.3s;
            &:after {
              transition: background 0.3s;
              background: sassvg('ArrowWhite', $color) no-repeat;
            }
          }

        }

      }

    }
  }
  .color-boxes-large-boxes {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, $color-box-large-size));
    grid-gap: 20px;
    &-box {
      height: $color-box-large-size;
      padding: 25px;
      border: 2px solid transparent;
      transition: background 0.3s;
      &-title {
        position: relative;
        font: 28px $klavikaLight;
        color: #ffffff;
        transition: color 0.3s;
        &:after {
          transition: background 0.3s;
          display: block;
          position: absolute;
          top: 50px;
          width: 24px;
          height: 16px;
          content: '';
          background: sassvg('ArrowWhite', #fff) no-repeat;
        }
      }
    }
  }

  //.box-blue { background-color: $blue; }
  //.box-green { background-color: $green; }
  //.box-red { background-color: $red; }
  //.box-purple { background-color: $purple; }
  //.box-yellow { background-color: $yellow; }
  //.box-brown { background-color: $brown; }
}


@media only screen and (max-width: $responsive) {
  .color-boxes-large {

    .color-boxes-large-boxes {
      margin: auto;
      max-width: 620px;
    }

  }
}

@media only screen and (max-width: $mobile) {

  .color-boxes-large {

    .color-boxes-large-boxes {
      grid-template-columns: 214px 214px;
      max-width: 450px;
      &-box {
        height: 214px;
        &-title {
          font-size: 24px;
          &:after {
            top: 40px;
          }
        }
      }
    }

  }
}

@media only screen and (max-width: 481px) {
  .color-boxes-large {

    .color-boxes-large-boxes {
      grid-template-columns: $color-box-large-size-mobile $color-box-large-size-mobile;
      max-width: 356px;
      &-box {
        height: $color-box-large-size-mobile;
        padding: 20px;
      }
    }

  }
}

@media only screen and (max-width: 350px) {
  .color-boxes-large {

    .color-boxes-large-boxes {
      max-width: $color-box-large-size-mobile;
      grid-template-columns: $color-box-large-size-mobile;
    }

  }
}
