$color-box-large-size: 298px;
$color-box-large-size-mobile: 168px;
$color-boxes-large-large-width: 1280px;

.color-boxes-large {

  .color-boxes-large-wrapper {

  }

  .color-boxes-large-inner {
    margin: 0 auto;
    padding: 120px 0;
    max-width: $max-width;
  }
  a {
    &:hover {
      text-decoration: none;
      .color-boxes-large-boxes-box {
        background: $white;
        transition: background 0.3s;
      }

      @each $class-name, $color in $box-colors {

          .color-boxes-large-boxes-box.#{$class-name} {

            border: 2px solid $color;
            .color-boxes-large-boxes-box-title {
              color: $color;
              transition: color 0.3s;
              &:after {
                transition: background 0.3s;
                background: sassvg('ArrowWhite', $color) no-repeat;
              }
            }

          }

      }

    }
  }
  .color-boxes-large-boxes {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, $color-box-large-size));
    grid-gap: 20px;
    &-box {
      height: $color-box-large-size;
      padding: 25px;
      border: 2px solid transparent;
      transition: background 0.3s;
      &-title {
        position: relative;
        font: 28px $klavikaLight;
        color: #ffffff;
        transition: color 0.3s;
        &:after {
          transition: background 0.3s;
          display: block;
          position: absolute;
          top: 50px;
          width: 24px;
          height: 16px;
          content: '';
          background: sassvg('ArrowWhite', #fff) no-repeat;
        }
      }
    }
  }

}


@media only screen and (max-width: $responsive) {
  .color-boxes-large {

    .color-boxes-large-boxes {
      margin: auto;
      max-width: 620px;
    }

  }
}

@media only screen and (max-width: $mobile) {

  .color-boxes-large {

    .color-boxes-large-boxes {
      grid-template-columns: 1fr 1fr;
      max-width: 450px;
      &-box {
        height: $color-box-large-size-mobile;
        &-title {
          font-size: 24px;
          &:after {
            top: 40px;
          }
        }
      }
    }

  }
}

@media only screen and (max-width: 481px) {
  .color-boxes-large {

    .color-boxes-large-boxes {
      max-width: 356px;
      &-box {
        padding: 20px;
      }
    }

  }
}

@media only screen and (max-width: 350px) {
  .color-boxes-large {

    .color-boxes-large-boxes {
      max-width: 168px;
      grid-template-columns: 1fr;
    }

  }
}