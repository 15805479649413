.tx-intpatentcalculator {
  text-align: left !important;
  line-height: initial;
  display: none;

  #cboxLoadedContent & {
    display: block;
    margin-top: 30px;
  }

  @media only screen and (max-width: 1023px) {
    .text-one-col .text-one-col-inner {
      padding: 0 20px;
    }
  }
}

.patent-container {
  display: none;
  max-width: 960px;
  margin: 0 auto;
  max-height: 400px;
}

#patentContainer {
  max-height: 400px;
}

.patent--form {
  display: grid;
  @media only screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
  @media only screen and (min-width: 1024px) {
    grid-template-columns: 1fr 2fr;
  }
}

.patent--form--countries {
  margin-bottom: 40px;
}

.patent--form--countries-wrap {
  column-count: 2;
  @media only screen and (min-width: 1024px) {
    column-count: 3;
  }
}

.patent--form--countries-country {
  margin: 0 0 5px 0;

  input {
    height: auto;
  }
}

.patent--form--toggle {
  text-decoration: underline !important;
}