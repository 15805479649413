@include imgBoxRight("img-form", $colors: $red);

.img-form {

  .img-form-wrapper {
    margin: 80px auto;
    background-color: $grey-lighter;
    padding: 80px 0;
  }

  .img-form-inner {
    max-width: $max-width;
    margin: 0 auto;
  }

  .img-form-info-title {
    margin-top: -10px;
  }

  .img-form-info-link {
    font: 12px $klavikaBold;
    color: $grey-dark;
    text-transform: uppercase;
  }

  .img-form-container {
    font: 14px/20px $klavikaLight;
  }
}

@media only screen and (max-width: $responsive) {
  .img-form {

    .img-form-wrapper {
      padding: 50px 0 80px 0;
    }

    .img-form-container {
      &-input {
        width: 100%;
      }
    }

    .img-form-image {
      max-width: 462px;
      margin: auto;
    }
  }
}

@media only screen and (max-width: 375px) {
  .img-form {

    .img-form-wrapper {
      margin: 80px auto;
    }

    .img-form-inner {
      max-width: $max-width;
      margin: 0 auto;
    }

  }
}