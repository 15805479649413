.map-box3 {

  .map-box3-wrapper {
    position: relative;
    margin: auto;
    max-width: 1280px;
  }

  .map-box3-inner {
    position: relative;
    margin: auto;
  }

  .map-box3-box {
    position: absolute;
    bottom: 60px;
    left: 0;
    //height: auto;
    height: 400px;
    width: 540px;
    background-color: $grey-lighter;
    border-top: 4px solid $red;
    padding: 10px 30px 30px 30px;
    z-index: 1;
    overflow: auto;
    .faq-toggle-list-item {
      padding-right: 0px;
    }
  }

  .locations-toggle-trigger {
    position: relative;
    cursor: pointer;
    color: $grey-light;
    &:after {
      content: "";
      display: block;
      position: absolute;
      right: 0px;
      top: 8px;
      background: sassvg('TabOpenGrey', $grey-light) center center no-repeat;
      width: 17px;
      height: 17px;
      cursor: pointer;
    }
    &.active {
      &:after {
        background: sassvg('TabCloseGrey', $grey-light) center center no-repeat;
      }
    }
  }
  .faq-toggle-description-box {
    //word-break: break-word;
    color: $grey-dark;
  }

  .map-box3-grid {
    display: grid;
    grid-template-columns: 220px 1fr;
    grid-gap: 20px;
  }

  .map-box3-title {
    @include boxTitle();
    margin-bottom: 10px;
    margin-top: 0px;
  }
  .map-box3-text {
    @include boxText();
    margin: 10px 0;
  }
  .map-box3-link {
    @include boxLink();
  }

  &-img {
    text-align: right;
    &-label {
      text-align: left;
      padding-left: 20px;
      font: 12px $klavika;
      color: $grey-light;
      margin-top: 10px;
    }
  }

  &-divider {
    height: 4px;
    background-color: $grey-dark;
    margin: 30px 0 25px 0;
  }
  &-kontakte {
    color: $grey-dark;
    font: 14px/17px $klavikaBold;
    padding: 5px 0;
  }
  &-street {
    @include contactTxt();
    @include contactPin();
    line-height: 27px !important;
    padding-top: 0px;
    background-position-y: 6px;
  }
  //&-street2 {
  //  @include contactTxt();
  //}
  //&-city {
  //  @include contactTxt();
  //}
  &-phone {
    @include contactTxt();
    @include contactPhone();
  }
  &-fax {
    @include contactTxt();
    @include contactFax();
  }
  &-email {
    @include contactTxt();
    @include contactEmail();
    margin-bottom: 10px;
  }
  &-link {
    margin-top: 5px;
  }

  .accordion {
    margin-top: 50px;
    &-toggle-list {
      background-color: transparent;
    }
    .faq-toggle-description {
      &-box {
        padding: 5px 0 20px 0;
      }
      p {
        margin-top: 0;
        margin-left: 20px;
        color: $grey-dark;
        //word-break: break-word;
      }
    }
  }

  .author-detail-back {
    max-width: 1280px;
    margin: 10px auto 30px auto;
    font: 12px $klavikaBold;
    color: $grey-light;
    text-transform: uppercase;
    a {
      background: sassvg('BackArrowGrey', $grey-light) left center no-repeat;
      padding-left: 35px;
    }
  }

}

@media only screen and (min-width: 1440px) {
  .map-box3 {
    .map-box3-box {
      height: 460px;
    }
  }
}

@media only screen and (max-width: 1023px) {
  .map-box3 {

    .map-box3-wrapper {

    }

    .map-box3-inner {
      padding: 0 10px;
    }


  }
}

@media only screen and (max-width: 768px) {
  .map-box3 {

    .map-box3-wrapper {
      display: flex;
      flex-flow: column;
    }

    .map-box3-inner {
      width: 100%;
    }
    .map-box3-box {
      position: relative;
      top: unset;
      bottom: unset;
      height: auto;
      max-width: 400px;
      width: 90%;
      margin: auto;
      margin-top: -80px;
      left: unset;
      transform: unset;
      z-index: 1;
    }
    .map-box3-layer {
      text-align: left;
      margin: 10px 30px;
    }
    .map-box3-title {
      margin-bottom: 10px;
      margin-top: 0px;
    }
    .map-box3-grid {
      grid-template-columns: 141px 1fr;
    }

  }
}

@media only screen and (max-width: 480px) {
  .map-box3 {

    .map-box3-grid {
      grid-template-columns: 1fr;
    }

  }
}