.page-header {
  position: sticky;
  top: 0;
  background-color: #ffffff;
  z-index: 2;
}

.header-container {
  position: relative;
  display: grid;
  grid-template-columns: 600px 1fr;
  max-width: $max-width;
  margin: 0 auto;
  min-height: 96px;
}

.hamburger-container,
.hamburger-container-language {
  display: none;
  background-color: #003da5;
  position: fixed;
  width: 100%;
  top: 84px;
  left: 0;

  @media screen and (min-width: $responsive) {
    background-color: #f6f6f6;
  }

  &-inner {
    max-width: $max-width;
    margin: 0 auto;
    padding: 20px 0;

    @media screen and (min-width: $responsive) {
      display: grid;
      grid-template-columns: 550px 1fr;
      padding: 33.5px 0;
    }
  }
}

.hamburger-container-language-inner {
  .language-holder {
    padding: 0 20px;
    color: #fff;
    height: 33px;
    display: flex;
    align-items: center;

    span:first-of-type {
      margin-left: 10px;
    }

    .language-selected {
      text-shadow: 1px 0 0 #fff;
    }

    a {
      color: #fff;
    }
  }
}

.grid {
  display: grid !important;
}

.hidden {
  display: none;
}

.header-logo {
  height: 80px;
  margin: auto 10px auto 0;
  background-size: cover;
  display: flex;

  @media screen and (min-width: $responsive) {
    margin: auto 0;
  }

  &-img {
    line-height: 0;
    margin: 25px 0 0px auto;
    z-index: 100;

    @media screen and (max-width: 1310px) {
      margin-right: 20px;
    }

    @media screen and (max-width: 1280px) {
      margin-right: 28px;
    }
  }
}

.header-nav {
  height: 80px;
  position: relative;

  ul {
    list-style: none;
  }

  li {
    display: flex;
    align-items: center;
    //padding-right: 5px;

    a {
      //padding: 5px;
      width: 100%;
      color: $grey-light;
      position: relative;
    }
  }

  &-list { // ul
    &-level-0 {
      display: flex;
      max-width: 530px;
      //height: 80px;
      margin-top: 22px;
      margin-bottom: 0;
      float: left;
    }

    &-level-1 {
      //width: 934px;
      height: auto;
      display: flex;
      flex-direction: column;

      li {
        display: inline-block;
        padding: 10px 25px 10px 15px;

        &:first-of-type {
          padding-top: 0;
        }

        &:last-of-type {
          padding-bottom: 0;
        }
      }
    }
  }

  &-item { // li
    &-level-0 {

      > a {
        white-space: nowrap;
      }

      &-holder-1 { // holder
        display: none;
        position: fixed;
        top: 84px;
        //left: 0;
        //right: 0;
        background-color: #F6F6F6;
        z-index: 1;
        min-width: 200px;
      }

      &.item-is-active {
        a {
          text-decoration: underline;
        }
      }


      //&:hover {
      //  > a span:after {
      //    position: absolute;
      //    top: 59px;
      //    left: 0;
      //    right: 0;
      //    content: '';
      //    display: block;
      //    height: 4px;
      //    background-color: #ffffff;
      //    z-index: 999;
      //  }
      //}

      &.item-has-sub:hover {
        background: #F7F7F7;

        .header-nav-item-level-0-holder-1 {
          display: grid;
          opacity: 1;
          visibility: visible;
          margin: 0;
        }
      }

    }

    &-level-1 {
      //padding: 15px 0;
      //height: 30px;
      line-height: 18px;

      & > li:first-child {
        padding-left: 15px;
      }

      a {
        text-decoration: none !important;
      }

      &.item-is-active {
        a {
          text-decoration: underline !important;
        }
      }
    }

    &-subholder {
      max-width: 935px;
      //margin: auto;
      padding: 20px 0;
      width: 100%;
    }
  }

  &-link {
    &-level-0 {
      padding: 15px 10px;
    }

    &-level-1 {
      display: block;
      width: auto !important;
      height: auto !important;
      color: $grey-dark;
      padding: 0 !important;
    }
  }

  .header-search {
    .btn-search {
      right: auto;
    }
  }
}

.menu-hamburger {
  height: 80px;
  margin-left: -30px;

  ul {
    list-style: none;
  }

  li {
    display: flex;
    align-items: center;
    padding: 0 30px;

    a {
      padding: 5px;
      width: 100%;
      //height: 100%;
      text-align: center;
      text-decoration: none;
      @include font($weight: 300,
      $color: $grey-light,
      $size: 18px,
      $line: 28px);
    }

    span {
      margin: auto;
    }
  }

  &-list { // ul
    &-level-0 {
      display: flex;
      margin: 0px;
      max-width: 700px;
      height: 80px;
    }
  }

  &-item { // li
    &-level-0 {

      &-holder-1 { // holder
        display: none;
        position: absolute;
        top: 80px;
        left: 0;
        right: 0;
        height: 80px;
        background-color: #F6F6F6;
        z-index: 1;
      }

      &.item-has-sub:hover {
        .header-nav-item-level-0-holder-1 {
          display: block;
          opacity: 1;
          visibility: visible;
          margin: 0;
        }
      }

    }

    &-level-1 {
      height: 80px;
      align-items: center;
      padding-left: 10%;
    }
  }

  &-link {
    &-level-0 {
      display: block;
      width: auto !important;
      height: auto !important;
    }

    &-level-1 {
      width: auto !important;
      height: auto !important;
      color: $grey-dark;
      font-size: 18px;
      line-height: 28px;
    }
  }
}

.language-holder {
  @media (min-width: $responsive) {
    position: absolute;
    right: calc((100vw - 680px) * -1);
    top: 0;
  }

  @media (min-width: 1280px) {
    right: -610px;
  }

  @media (min-width: 1315px) {
    right: -645px;
  }

  & > span:hover {
    text-shadow: 1px 0px 0px #7C7E7F;
  }
}

.btn-open-search {
  @media (min-width: $responsive) {
    position: absolute;
    right: calc((100vw - 635px) * -1);
    top: -5px;
  }

  @media (min-width: 1280px) {
    right: -655px;
  }

  @media (min-width: 1315px) {
    right: -690px;
  }
}

.language-selected {
  text-shadow: 1px 0px 0px #7C7E7F;

  &:hover {
    cursor: pointer;
  }
}

@include hamburger($class: "menu", $left: 0px);
