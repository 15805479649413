.search-result {

  .search-result-wrapper {
    margin: 20px auto;
  }
  .search-result-inner {
    max-width: $max-width;
    margin: 0 auto;
  }

  .header-search {
    display: block;
  }
  .karriere-grid-item {
    display: grid;
    grid-template-columns: 150px 150px;
    grid-gap: 20px;
    &-select {
      .header-search-form-input {
        height: 27px;
        width: 129px;
      }
      .btn-search {
        height: 30px;
      }
    }
  }

  .karriere-grid-order-holder {
    display: grid;
    grid-template-columns: 1fr 150px;
    margin-top: 38px;
  }
  .karriere-grid-sort {
    justify-self: end;
    display: flex;
    align-items: center;
    &-label {
      font: 14px $klavikaLight;
      color: $grey-light;
    }
    &-box {
      display: inline-block;
      margin-left: 10px;
      width: 15px;
      height: 14px;
      background: sassvg('ViewToggleGridDarkGrey', $grey-dark) no-repeat center left;
      background-repeat: no-repeat;
      cursor: pointer;
      &.inactive {
        background: sassvg('ViewToggleGridDarkGrey', $grey-light) no-repeat center left;
      }
    }
    &-list {
      display: inline-block;
      margin-left: 10px;
      width: 15px;
      height: 14px;
      background: sassvg('ViewToggleLinsesGrey', $grey-dark) no-repeat center left;

      background-repeat: no-repeat;
      cursor: pointer;
      &.inactive {
        background: sassvg('ViewToggleLinsesGrey', $grey-light) no-repeat center left;
      }
    }
  }
  .search-result-link {
    display: inline-block;
    margin-bottom: 15px;
    color: $grey-dark;
    font-family: $klavikaBold;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
  }

  .tx-indexedsearch-form {
    position: relative;
    display: flex;
    align-items: flex-end;
  }
  .tx-indexedsearch-field-holder {
    position: relative;
    max-width: 220px;
  }
  .tx-indexedsearch-searchbox-button {
    display: inline-block;
    margin-left: 20px;
    font: 12px $klavikaBold;
    color: $grey-dark;
    text-transform: uppercase;
    border-bottom: unset;
    cursor: pointer;
  }
  .tx-indexedsearch {
    &-res {
      padding: 5px 20px;
      h3 {
        display: inline-block;
        padding: 5px 0px;
        margin: 5px 0;
        a {
          font: 24px/28px $klavika;
          color: $grey-dark;
        }
      }
      .breadcrumb {
        margin: 5px 0;
      }
    }
    &-description {
      font: 16px/26px $klavikaLight;
      color:$grey-dark;
    }
    &-redMarkup {
      font-family: $klavika;
      background-color: #ffee1d;
    }
  }

  .search-button {
    display: inline;
    color: #313131;
    font-family: $klavikaBold;
    font-size: 12px;
    line-height: 12px;
    text-transform: uppercase;
    border-bottom: unset;
    height: unset;
  }

  .searchFor {
    margin-bottom: 30px;
  }

  .search-result-single-holder {
    margin-top: 70px;
    & > div.tx-indexedsearch-res:nth-child(odd) {
      background-color: $grey-lighter;
    }
  }

  .tx-indexedsearch-browsebox {
    strong {
      font-family: $klavikaBold;
    }
  }

  .tx-indexedsearch-info-noresult {
    margin-top: 40px;
    color: $red;
  }

}

@media only screen and (max-width: $responsive) {

}

@media only screen and (max-width: 768px) {
  .search-result {
    &-inner {
      padding: 0 10px;
    }

  }
}


ul.tx-indexedsearch-browsebox {
  margin-top: 40px;
  li {
      display: inline-block;
      margin-right: 5px;
      margin-bottom: 6px;
      padding: 0 5px;
    a {
      font-size: 14px;
    }
    &:first-child,
    &:last-child {
      border: none;
    }
    //&.tx-indexedsearch-browselist-currentPage  {
    //
    //}
  }
}