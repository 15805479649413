.team-grid {

  .team-grid-wrapper {
    margin: 20px auto;
  }

  .team-grid-inner {
    max-width: $max-width;
    margin: 0 auto;
  }

  .team-grid-search-holder {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 410px 1fr 1fr 1fr;
  }

  .header-search {
    display: block;
    max-width: 300px;
  }

  .team-grid-item {
    &-select {
      justify-self: end;
    }
  }

  .team-grid-sword-wrapper {
    display: inline-block;
    position: relative;
  }

  .header-search-form-input {
    width: calc(100% - 20px);
    height: 27px;

    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $grey-light;
      font: 14px $klavikaLight;
    }
  }

  .team-grid-order-holder {
    display: grid;
    grid-template-columns: 1fr 150px;
    margin-top: 38px;
  }

  .team-grid-alphabet {
    margin-left: -7px;

    &-letter {
      display: inline-block;
      width: 26px;
      font: 18px $klavikaLight;
      color: $grey-light;
      text-align: center;

      &:hover {
        font-family: $klavikaBold;
        cursor: pointer;
      }

      &.active {
        font-family: $klavikaBold;
      }
    }
  }

  .team-grid-sort {
    justify-self: end;

    &-label {
      font: 14px $klavikaLight;
      color: $grey-light;
    }

    &-box {
      display: inline-block;
      margin-left: 10px;
      width: 15px;
      height: 14px;
      background: sassvg('ViewToggleGridDarkGrey', $grey-dark) no-repeat center left;
      background-repeat: no-repeat;
      cursor: pointer;

      &.inactive {
        background: sassvg('ViewToggleGridDarkGrey', $grey-light) no-repeat center left;
      }
    }

    &-list {
      display: inline-block;
      margin-left: 10px;
      width: 15px;
      height: 14px;
      background: sassvg('ViewToggleLinsesGrey', $grey-dark) no-repeat center left;

      background-repeat: no-repeat;
      cursor: pointer;

      &.inactive {
        background: sassvg('ViewToggleLinsesGrey', $grey-light) no-repeat center left;
      }
    }
  }

  .team-grid-single-holder {
    margin-top: 40px;
    display: grid;
    grid-template-columns: 300px 300px 300px 300px;
    grid-gap: 27px;

    &.display-grid {
      display: grid !important;
    }

    &.sort-grid {
      .team-grid-single-item {
        &.inactive {
          display: none;
        }

        &.active {
          display: block;
        }
      }
    }

    &.sort-list {
      .team-grid-single-item {
        &.inactive {
          opacity: 0.2;
        }
      }
    }

    .team-grid-single-holder-column {
      & > div {
        margin-bottom: 20px;
      }

      & > div:last-child {
        margin-bottom: 0;
      }
    }
  }

  .team-grid-single-item {
    position: relative;
    height: 300px;
    /****************** overlay *****************/
    &:hover .team-grid-single-item-overlay {
      height: 100%;
      background-color: $grey-dark;
      opacity: 1;
    }

    &-overlay {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      overflow: hidden;
      width: 100%;
      height: 100px;
      transition: .5s ease;
      padding: 10px 20px;
      background-color: hsla(0, 0%, 0%, 0.3);
    }

    /******************* END overlay END ***************/

    &-position {
      font: 14px $klavikaBold;
      color: #ffffff;
      margin: 15px 0px 5px 0;
    }

    &-name {
      font: 18px $klavikaLight;
      color: #ffffff;
      margin: 10px 0;

      &-list {
        font: 18px $klavikaLight;
        color: $grey-dark;
        margin: 10px 0;

        &:hover {
          text-shadow: 0px 0px 1px rgba(49, 49, 49, 1);

          a {
            text-shadow: 0px 0px 1px rgba(49, 49, 49, 1);
            text-decoration: none;
            color: $grey-dark;
          }
        }

        a {
          font: 18px $klavikaLight;
          color: $grey-dark;
          display: block;
        }
      }
    }

    &-divider {
      height: 4px;
      width: 60px;
      background-color: #FFFFFF;
      margin: 45px 0 25px 0;
    }

    &-place {
      @include contactTxt($color: #ffffff);
      @include contactPin($color: #ffffff);
    }

    &-phone {
      @include contactPhone($color: #ffffff);

      a {
        @include contactTxt($color: #ffffff);
      }
    }

    &-email {
      @include contactEmail($color: #ffffff);

      a {
        @include contactTxt($color: #ffffff);
      }
    }

    &-detail {
      a {
        display: inline-block;
        text-align: left;
        margin-top: 20px;
        padding: 0;
        font-family: $klavikaBold;
        color: #ffffff;
        font-size: 12px;
        line-height: 15px;
        text-transform: uppercase;
      }
    }
  }

  /********** display list ***********/
  .team-grid-single-holder.sort-list {
    display: none;
    grid-gap: 40px;
    grid-auto-flow: column;
    grid-auto-columns: 400px;
    grid-template-columns: unset;

    .team-detail-image {
      display: none;
    }

    .team-grid-single-item {
      height: auto;
      /****************** overlay *****************/
      &.active:hover .team-grid-single-item-overlay {
        display: block;
      }

      // Display popup below title
      &-overlay {
        min-height: 330px;
        width: 330px;
        display: none;
        background-color: unset !important;
        padding: 0;
        top: -26px;
        left: 210px;
        z-index: 10;

        &-inner {
          min-height: 300px;
          margin: 15px;
          background-color: $grey-dark;
          padding: 10px 20px;
        }

        &:after {
          display: block;
          content: '';
          position: absolute;
          top: 30px;
          left: 7px;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 7px 8px 7px 0;
          border-color: transparent $grey-dark transparent transparent;
        }
      }

      /******************* END overlay END ***************/

      &-name {
        position: relative;

        &-list {
          display: block;
        }

      }

    }

    .team-grid-single-holder-column.column-3 {
      .team-grid-single-item-overlay {
        //top: -26px;
        left: -330px;

        &-inner {

        }

        &:after {
          right: 8px;
          left: unset;
          border-width: 7px 0 8px 7px;
          border-color: transparent transparent transparent $grey-dark;
        }
      }
    }

    .team-grid-single-letter-headline {
      display: block;
      height: 30px;
      background-color: #F7F7F7;
      padding: 5px 10px;
      font: 18px $klavikaBold;
      color: $grey-dark;
    }

  }
}

@media only screen and (max-width: $max-width) {
  .team-grid {
    .team-grid-single-holder {
      grid-template-columns: 300px 300px;
      justify-content: center;
    }
    .team-grid-single-holder.sort-list {
      grid-gap: 20px;
      grid-auto-columns: 300px;
    }
  }
}

@media only screen and (max-width: $responsive) {
  .team-grid {
    .team-grid-inner {
      padding: 0 10px;
    }

    .team-grid-search-holder {
      grid-gap: 10px;
      grid-template-columns: 220px 1fr 1fr 1fr;

      .header-search {
        min-height: unset;
        max-width: unset;
      }
    }

    .team-grid-order-holder {
      grid-template-columns: 1fr;
      grid-template-areas:
        'sort'
        'alphabet';
      grid-gap: 20px;
    }

    .team-grid-alphabet {
      grid-area: alphabet;
    }

    .team-grid-sort {
      grid-area: sort;
      justify-self: right;
    }

    .header-search-form-input {
      height: 27px;

    }

    /********** display list ***********/
    .team-grid-single-holder.sort-list {
      grid-template-rows: repeat(4, auto);
      grid-auto-columns: 1fr;

      .team-grid-single-item {
        /****************** overlay *****************/
        &.active:hover .team-grid-single-item-overlay {
          display: none;
        }

        &-name-list {
          &:hover {
            text-shadow: unset;

            a {
              text-shadow: unset;
              //  text-decoration: unset;
              color: unset;
            }
          }

          a:hover {
            text-shadow: 0px 0px 1px rgba(49, 49, 49, 1);
            text-decoration: none;
            color: $grey-dark;
          }
        }
      }

      .team-grid-single-holder-column {
        .team-grid-single-item-overlay {
          display: none;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .team-grid {
    .team-grid-single-holder {
      justify-content: center;
      grid-gap: 10px;
      grid-template-columns: 174px 174px;
    }

    .select2.select2-container {
      max-width: unset;
      width: 100%;
    }

    select {
      width: 100%;
    }

    .team-grid-sword-wrapper {
      display: flex;
    }

    .team-grid-search-holder {
      grid-template-columns: 1fr 1fr;
    }

    .team-grid-alphabet {
      display: none;
    }

    .team-grid-item-select {
      justify-self: unset;
    }

    .header-search-form-input {
      width: calc(100% - 20px);
      //width: 100%;
      height: 27px;
    }

    .team-grid-single-item {
      height: 174px;
      /****************** overlay *****************/
      &.active:hover .team-grid-single-item-overlay {
        //display: none;
      }

      &-overlay {
        padding: 10px;
        height: 130px;
      }

      &-divider {
        margin: 10px 0 10px 0;
      }

      &-place {
        display: none;
      }

      &-phone {
        display: none;
      }

      &-email {
        display: none;
      }

      &-name {
        min-height: 42px;
      }

      &-position {
        min-height: 50px;
        margin-top: 10px;
      }

      &-detail {
        line-height: 1;

        a {
          margin-top: 5px;
        }
      }
    }

    /********** display list ***********/
    .team-grid-single-holder.sort-list {
      grid-template-rows: repeat(4, auto);
      grid-auto-columns: 1fr;
    }
  }
}

@media only screen and (max-width: 640px) {
  /********** display list ***********/
  .team-grid {
    .team-grid-single-holder.sort-list {
      columns: unset;

      .team-grid-single-item {

        /****************** overlay *****************/
        &.active:hover .team-grid-single-item-overlay {
          //display: block;
        }

        &-overlay {
          top: 30px;
          left: 0;

          &-inner {
            margin: 15px 0;
          }

          &:after {
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-bottom: 8px solid $grey-dark;
            top: 0px;
            left: 7px;
          }
        }

        /******************* END overlay END ***************/

      }

      .team-grid-single-holder-column.column-3 {
        .team-grid-single-item-overlay {
          top: 30px;
          left: 10px;

          &:after {
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-bottom: 8px solid $grey-dark;
            top: 0px;
            left: 7px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .team-grid {

    .team-grid-wrapper {
      margin: 80px auto;
    }

    .team-grid-inner {
      max-width: $max-width;
      margin: 0 auto;
    }

    .team-grid-item {
      &-select {
        float: none;
        margin: 0 0 10px 0;

        select {
          width: 100%;
        }
      }
    }

  }
}

.news-grid-search-holder,
.tx-indexedsearch-field-holder,
.team-grid-area-search {
  @media only screen and (max-width: 1024px) {
    .header-search-form-input {
      border-color: #7c7e7f !important;
      color: #7c7e7f !important;
    }

    .SearchGrey {
      filter: unset !important;
    }
  }
}