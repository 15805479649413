.header-search {
  display: flex;

  &-form {
    position: relative;
    margin: auto;

    &-input {
      width: 185px;
      padding-right: 20px;
      border: none;
      border-bottom: 1px solid #fff;
      height: 30px;
      color: #fff;
      background-color: transparent;

      &::placeholder {
        color: #fff;
      }

      &-main {
        width: 0;
        padding-right: 0;
        font-size: 18px;
        height: initial;

        &::placeholder {
          color: #8b8d8e;
        }
      }
    }

    &-holder {
      margin: auto;
    }

    .btn-search {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      width: 40px;
      height: 35px;
      background: transparent;
      color: #000;
      text-align: center;
      border: none;
      border-radius: 0;
      appearance: none;
      cursor: pointer;
      text-decoration: none;
    }
  }
}

@media screen and (min-width: $responsive) {
  .header-search-form {
    &-input {
      border-bottom: 1px solid $grey-light;
      color: $grey-light;

      &::placeholder {
        color: $grey-light;
      }
    }

    .btn-search {
      right: -10px;
    }
  }
}

@media screen and (max-width: $responsive) {
  .header-search-form {
    .SearchGrey {
      filter: invert(0%) sepia(21%) saturate(1368%) hue-rotate(306deg) brightness(274%) contrast(97%);
    }
  }
}

.btn-search {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -10px;
  width: 40px;
  height: 35px;
  background: transparent;
  color: #000;
  text-align: center;
  border: none;
  border-radius: 0;
  appearance: none;
  cursor: pointer;
  text-decoration: none;
}

.btn-open-search {
  width: 30px;
  height: 35px;
  background: transparent;
  color: #000;
  text-align: center;
  border: none;
  border-radius: 0;
  appearance: none;
  cursor: pointer;
  text-decoration: none;
}

.page-header {
  .header-search {

    &-form {
      //margin: 0;
      width: 100%;

      &-input {
        width: 100%;
        padding-right: 0;
      }
    }
  }
}
