$fakten-box-size: 140px;

.fact {
  background-color: $grey-lighter;
  .fact-inner {
    padding: 70px 0 160px 0;
    max-width: $max-width;
    margin: 0 auto;
  }
  .fact-boxes {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, $fakten-box-size));
    grid-gap: 20px;
    justify-content: center;
  }
  .fact-box {
      width: $fakten-box-size;
      height: $fakten-box-size;
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &-layer {
        align-self: center;
        margin: auto;
      }
      &-number {
        color: $grey;
        text-align: center;
        font: 46px/38px $klavikaBold;
      }
      &-text {
        color: $grey;
        text-align: center;
        margin: 7px 0;
        font: 18px $klavikaLight;
        height: 30px;
        display: flex;
        align-items: center;
      }
      &-divider {
          height: 2px;
          width: 40px;
          background-color: $grey;
          margin: auto;
      }
  }
  .fact-smalltitle {
    text-align: center;
    font: 18px $klavikaBold;
    color: $grey-dark;
  }
  .fact-headline {
    text-align: center;
    font: 28px $klavikaLight;
    color: $grey-dark;
    margin-top: 5px;
    margin-bottom: 40px;
  }
}

