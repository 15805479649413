.text-one-col {

  .text-one-col-wrapper {

  }

  .text-one-col-inner {
    max-width: $max-width;
    margin: auto;
    font: 18px/28px $klavikaLight;
    color: $grey-light;
    @include marginsElementSet;
  }

  @include bullet-list();

}

@media only screen and (max-width: 1023px) {
  .text-one-col {

    .text-one-col-wrapper {

    }

    .text-one-col-inner {
      padding: 0 10px;
    }


  }
}