.header-inner {

  .header-inner-wrapper {
    position: relative;
    display: flex;
    flex-flow: column;
    max-width: 100%;
    //margin: 0 auto 60px;
  }

  .header-inner-image-desktop {
    display: block;
  }
  .header-inner-image-desktop-2,
  .header-inner-image-mobile {
    display: none;
  }
  .header-inner-box-wrapper {
    position: absolute;
    left: calc((100vw - 1280px) / 2);
    width: 100%;
    max-width: 1280px;
    height: 100%;
    display: grid;
    align-items: center;
  }
  .header-inner-box {
    display: grid;
    justify-content: center;
    align-items: center;
    width: 290px;
    height: 290px;
    padding: 30px;
    //@include squareTop($color: $green);
    @each $class-name, $bgcolor in $box-colors {
      &.small-#{$class-name} {
        //@include squareTop($color: $bgcolor);
      }

    }
    .header-inner-box-title {
      margin: 0;
      color: #ffffff;
      font: 18px $klavikaBold;
    }
    .header-inner-box-text {
      color: #ffffff;
      font: 28px $klavikaLight;
      margin: 10px 0;
    }
    .header-inner-box-line {
      height: 2px;
      width: 60px;
      background-color: #FFFFFF;
    }
  }

  .breadcrumb-holder {
    position: static;
    max-width: 1280px;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 1280px) {
  .header-inner {
    .header-inner-box-wrapper {
      left: 0;
    }

    .header-inner-box {
      margin-left: 50px;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .header-inner {
    .header-inner-image-desktop-2 {
      display: block;
    }
    .header-inner-image-desktop {
      display: none;
    }
  }
}

@media only screen and (max-width: 950px) and (min-width: 768px) {
  .header-inner {
    .header-inner-box {
      width: 240px;
      height: 240px;

      .header-inner-box-title {
        font: 14px KlavikaBold;
      }

      .header-inner-box-text {
        font: 20px KlavikaLight;
      }
    }
  }
}

@media only screen and (max-width: 768px) and (min-width: 580px) {
  .header-inner {
    .header-inner-box {
      width: 200px;
      height: 180px;

      .header-inner-box-title {
        font: 12px KlavikaBold;
      }

      .header-inner-box-text {
        font: 16px KlavikaLight;
      }

    }
  }
}

@media only screen and (max-width: 768px) {
  .header-inner {

    .header-inner-position {
      max-width: 1280px;
      margin: auto;
      width: 100%;
    }

  }
}

@media only screen and (max-width: 580px) {
  .header-inner {
    .header-inner-image-desktop-2 {
      display: none;
    }
    .header-inner-image-mobile {
      display: block;
    }
    .header-inner-box-wrapper {
      justify-content: center;
    }
    .header-inner-box {
      margin-left: 0;
    }
  }
}
