.author {

  .author-wrapper {
    padding: 30px 0 0 0;
  }

  .author-inner {
    margin: auto;
  }

  .author-items-wrapper {
    display: grid;
    max-width: $max-width;
    flex-wrap: wrap;
    margin: 16px auto auto;
    grid-template-columns: 1fr;
    @media (min-width: $mobile) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  .author-detail-author {
    max-width: $max-width;
    margin: auto;
    margin-top: 40px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font: 12px $klavikaBold;
    color: $grey;

    img {
      width: 80px;
      height: 80px;
      margin-right: 10px;
      border-radius: 50%;
    }

    span {
      font: 12px $klavikaLight;
      text-transform: initial;
    }
  }

  .author-detail-back {

    max-width: 1280px;
    margin: 10px auto 20px auto;
    font: 12px $klavikaBold;
    color: $grey-light;
    text-transform: uppercase;

    a {
      background: sassvg('BackArrowGrey', $grey-light) left center no-repeat;
      padding-left: 35px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .author {
    .author-inner {
      padding: 0 10px;
    }
  }
}