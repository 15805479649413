.bullet-list {

  .bullet-list-wrapper {

  }

  .bullet-list-inner {
    max-width: $max-width;
    margin: auto;
  }

  .bullet-list-title {
    font: 28px/38px $klavikaLight;
    color: $grey-dark;
  }

  ul {
    margin-left: 30px;
    li {
      position: relative;
      padding-left: 30px;
      font: 18px/28px $klavikaLight;
      color: $grey;
      margin-top: 15px;
      &:before {
        position: absolute;
        top: 5px;
        left: 0;
        width: 14px;
        height: 14px;
        background-color: $green;
        content: '';
      }

    }
  }

}

@media only screen and (max-width: 1023px) {
  .bullet-list {

    .bullet-list-wrapper {

    }

    .bullet-list-inner {
      padding: 0 10px;
    }


  }
}