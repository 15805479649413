//@include imgBoxLeft($class:"form-images", $colors: $red);

.form-images {

  .form-images-wrapper {
    margin: 80px auto;
    padding: 80px 0;
  }

  .form-images-inner {
    max-width: $max-width;
    margin: 0 auto;
  }

  .form-images-container {
    position: relative;
    min-height: 990px;
    max-width: 700px;
    border-top: 5px solid $red;
    background-color: $grey-lighter;
    @each $class-name, $bgcolor in $box-colors {
      &.#{$class-name} {
        border-top: 5px solid $bgcolor;
      }
    }
  }

  .form-images-image-holder {
    position: absolute;
    top: 50px;
    left: calc(100% - 120px);
    width: 100%;
    max-width: 380px;
  }

  .form-images-image-holder2 {
    top: unset;
    bottom: 90px;
    .form-images-image-square {
      position: relative;
      line-height: 0;
      //@include squareLeft($color:$red);
      @each $class-name, $bgcolor in $box-colors {
        &.#{$class-name} {
          //@include squareLeft($color:$bgcolor);
        }
      }

    }
  }

  .form-images-caption {
    font: 12px/20px $klavika;
    color: $grey-light;
    padding-left: 130px;
  }

  .form-images-submit {
    font: 12px $klavikaBold;
    color: $grey-dark;
    border: none;
    height: unset;
    text-transform: uppercase;
    cursor: pointer;
  }

  .form-images-section-text {
    font: 12px/20px $klavikaLight;
    color: $grey-light;
    padding-left: 27px;
  }

  .form-images-box {
    margin: 60px 190px 20px 30px;
  }

  .form-images-lead {
    font: 18px/30px $klavikaBold;
    color: $grey-dark;
    margin: 15px 0 5px 0;
  }

  .form-images-header {
    font: 28px/38px $klavikaLight;
    color: $grey-dark;
    margin: 5px 0 25px 0;
  }

  .form-images-section {
    margin: 40px 0;
    &-grid {
      display: grid;
      grid-gap: 30px 40px;
      grid-template-columns: 220px 220px;
    }
    &-protection {
      .form-images-input {
        display: block;
        margin: 10px 0 20px 0;
      }
      .form-images-section-headline {
        margin-bottom: 15px;
      }
    }
    &-interests {
      .form-images-section-headline {
        margin-bottom: 15px;
      }
    }
  }
  .img-form-message {
    border: 1px solid #7c7e7f;
    margin-top: 10px;
    width: 100%;
    max-width: 475px;
  }
  .form-images-section-headline {
    font: 14px/17px $klavikaBold;
    color: $grey-dark;
    margin: 0px 0 5px 0;
  }

  .form-images-input {
    font: 14px/17px $klavikaLight;
    color: $grey-light;
  }


  /****** checkbox style ***********/

  /* Base for label styling */
  [type="checkbox"]:not(:checked),
  [type="checkbox"]:checked {
    position: absolute;
    left: -9999px;
  }
  [type="checkbox"]:not(:checked) + label,
  [type="checkbox"]:checked + label {
    position: relative;
    padding-left: 1.95em;
    cursor: pointer;
    font: 14px/17px $klavikaLight;
    color: $grey-light;
  }

  /* checkbox aspect */
  [type="checkbox"]:not(:checked) + label:before,
  [type="checkbox"]:checked + label:before {
    content: '';
    position: absolute;
    top: 1px;
    left: 0;
    height: 14px;
    width: 14px;
    background: $grey-header;
  }
  /* checked mark aspect */
  [type="checkbox"]:not(:checked) + label:after,
  [type="checkbox"]:checked + label:after {
    content: '\2713\0020';
    position: absolute;
    top: 2px;
    left: 1px;
    width: 14px;
    height: 14px;
    font-size: 13px;
    line-height: 1;
    text-align: center;
    color: $red;
    transition: all .2s;
    font-family: 'Lucida Sans Unicode', 'Arial Unicode MS', Arial;
  }
  /* checked mark aspect changes */
  [type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0);
  }
  [type="checkbox"]:checked + label:after {
    opacity: 1;
    transform: scale(1);
  }
  /* disabled checkbox */
  [type="checkbox"]:disabled:not(:checked) + label:before,
  [type="checkbox"]:disabled:checked + label:before {
    box-shadow: none;
    border-color: #bbb;
    background-color: $grey-header;
  }
  [type="checkbox"]:disabled:checked + label:after {
    color: #999;
  }
  [type="checkbox"]:disabled + label {
    color: #aaa;
  }
  /* accessibility */
  //[type="checkbox"]:checked:focus + label:before,
  //[type="checkbox"]:not(:checked):focus + label:before {
  //  border: 2px dotted blue;
  //}

  /* hover style just for information */
  label:hover:before {
    background-color: $grey-light;
  }

  .form-images-container {
    @each $class-name, $bgcolor in $box-colors {
      &.#{$class-name} {
        [type="checkbox"]:checked + label:after {
          color: $bgcolor;
        }
      }
    }
  }
}


@media only screen and (max-width: 1023px) {
  .form-images {

    .form-images-wrapper {
      padding: 50px 10px 80px 10px;
    }

    .form-images-container {
      min-height: unset;
      max-width: unset;
      padding: 1px;
    }

    .form-images-image-holder {
      display: none;
    }


    .form-images-box {
      margin: 40px 30px;
    }

  }
}


@media only screen and (max-width: 640px) {
  .form-images {

    .form-images-wrapper {
      margin: 80px auto;
    }

    .form-images-inner {
      max-width: $max-width;
      margin: 0 auto;
    }

    .form-images-section {
      &-grid {
        display: grid;
        grid-gap: 30px;
        grid-template-columns: 1fr 1fr;
      }
      &-interests {
        .form-images-section-grid {
          grid-gap: 20px;
          grid-template-columns: 1fr;
        }
      }
    }
    .img-form-message {
      max-width: 535px;
      height: 68px;
    }
  }
}


@media only screen and (max-width: 480px) {
  .form-images {

    .form-images-section {
      &-grid {
        grid-template-columns: 120px 120px;
      }
    }

  }
}



@media only screen and (max-width: 360px) {
  .form-images {

    .form-images-section {
      &-grid {
        grid-template-columns: 1fr;
      }
    }

  }
}
