.quote-text {

  .quote-text-wrapper {

  }

  .quote-text-inner {
    //max-width: $max-width;
    margin: auto;
  }
  .quote-text-layer {
    background: linear-gradient(to right,  rgba(30,87,153,0.1) 50%,rgba(125,185,232,0) 0%);
  }
  .quote-text-container {
    max-width: $max-width;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .quote-text-quote {
    font: 28px/38px $klavikaLightItallic;
    color: $grey;
    padding: 30px 30px 30px 0;
    display: flex;
    flex-direction: column;
    margin: auto;
    h1, h2, h3, p {
      margin-top: 0;
      margin-bottom: 15px;
    }
  }
  .quote-text-text {
    font: 18px/28px $klavikaLight;
    color: $grey-light;
    background-color: #ffffff;
    padding-left: 40px;
    h1, h2, h3, p {
      margin-top: 0;
      margin-bottom: 15px;
    }
  }
  .quote-text-author {
    font: 12px/28px $klavikaMedium;
    color: $grey-light;
  }
}

@media only screen and (max-width: 1023px) {
  .quote-text {

    .quote-text-container {
      grid-template-columns: 1fr;
      grid-gap: 20px;
    }
    .quote-text-layer {
      background: none;
    }
    .quote-text-quote {
      background: rgba(30,87,153,0.1);
      padding: 30px 10px;
    }
    .quote-text-text {
      padding: 0 10px;
    }

  }
}