.frame-type-felogin_login {
  max-width: 905px;
  width: 100%;
  margin: 60px auto;

  fieldset {
    border: none;
    padding: 0;
  }

  legend {
    display: none;
  }

  input[type="submit"] {
    margin-top: 20px;
    padding: 8px 25px;
    height: auto;
    background: $blue-dark;
    cursor: pointer;
    color: #fff;
  }
}