.img-box1 {

  .img-box1-wrapper {

  }

  .img-box1-inner {
    max-width: $max-width;
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
    position: relative;
  }

  .img-box1-image {

  }
  .img-box1-holder {
    position: relative;
  }
  .img-box1-info {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -480px;
    height: 341px;
    width: 540px;
    padding: 30px;
    background-color: $grey-lighter;
    @include border-top($color: $green);
    //@include squareRight($green);

    &-title {
      text-align: right;
      font: 18px $klavikaBold;
    }
    &-text {
      text-align: right;
      font: 28px $klavikaLight;
      margin: 10px 0;
    }
    &-link {
      float: right;
      display: inline-block;
      margin-top: 30px;
      text-transform: uppercase;
      @include link-style;
    }
    &-container {
      display: flex;
      justify-content: flex-end;
      height: 100%;
      &-inner {
        margin: auto 0;
      }
    }
  }

  .header-search {
    justify-content: flex-end;
    &-form {
      text-align: right;
      margin: unset;
    }
  }

}

@media screen and (min-width: 1280px) {
  .img-box1 {
    .img-box1-holder {
      margin-right: 170px;
    }
  }
}

@media only screen and (max-width: $responsive) {
  .img-box1 {

    .img-box1-wrapper {

    }

    .img-box1-inner {
      justify-content: center;
    }

    .img-box1-image {
      width: 100%;
      height: auto;
    }
    .img-box1-holder {
      display: flex;
      flex-flow: column-reverse;
    }
    .img-box1-info {
      position: relative;
      top: unset;
      bottom: unset;
      left: unset;
      right: unset;
      transform: unset;
      margin: -70px auto 0 auto;
      width: auto;
      height: auto;
      &-title {
        text-align: left;
        margin-top: 0;
      }
      &-text {
        text-align: left;
      }
      &-link {
        float: none;
      }
      &-container {
        justify-content: flex-start;
        &-inner {
          width: 100%;
        }
      }
    }

    .header-search {
      justify-content: flex-start;
      min-height: unset;
      &-form {
        width: 100%;
        .header-search-form-input {
          width: 94%;
          color: $grey-dark;
          border-bottom: 1px solid $grey-light;
        }
      }
    }

  }
}

