.karriere-grid {

  .karriere-grid-wrapper {
    margin: 0 auto;
    background-color: $grey-lighter;
    padding: 50px 0;
    margin-top: 30px;
  }
  .karriere-grid-inner {
    max-width: $max-width;
    margin: 0 auto;
  }

  .karriere-grid-search-holder {
    display: grid;
    grid-template-columns: 1fr 180px;
  }
  .header-search {
    display: block;
  }
  .karriere-grid-item {
    display: grid;
    grid-template-columns: 150px 150px 150px 150px;
    grid-gap: 20px;
    &-select {
      .header-search-form-input {
        height: 27px;
        width: 129px;
        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: $grey-light;
        }
      }
      .btn-search {
        height: 30px;
      }
    }
  }

  .karriere-grid-order-holder {
    display: grid;
    grid-template-columns: 1fr 150px;
    margin-top: 38px;
  }
  .karriere-grid-sort {
    justify-self: end;
    display: flex;
    align-items: center;
    &-label {
      font: 14px $klavikaLight;
      color: $grey-light;
    }
    &-box {
      display: inline-block;
      margin-left: 10px;
      width: 15px;
      height: 14px;
      background: sassvg('ViewToggleGridDarkGrey', $grey-dark) no-repeat center left;
      background-repeat: no-repeat;
      cursor: pointer;
      &.inactive {
        background: sassvg('ViewToggleGridDarkGrey', $grey-light) no-repeat center left;
      }
    }
    &-list {
      display: inline-block;
      margin-left: 10px;
      width: 15px;
      height: 14px;
      background: sassvg('ViewToggleLinsesGrey', $grey-dark) no-repeat center left;

      background-repeat: no-repeat;
      cursor: pointer;
      &.inactive {
        background: sassvg('ViewToggleLinsesGrey', $grey-light) no-repeat center left;
      }
    }
  }

  .karriere-grid-single-holder {
    margin-top: 40px;
    display: grid;
    grid-template-columns: 300px 300px 300px 300px;
    grid-gap: 27px;
    &.sort-grid {
      .karriere-grid-single-item {
        &.inactive {
          display: none;
        }
        &.active {
          display: block;
        }
      }
    }
    &.sort-list {
      .karriere-grid-single-item {
        &.inactive {
          opacity: 0.2;
        }
      }
    }
    & > div:nth-child(odd) {
      background-color: #DFE3E6;
    }
  }
  .karriere-grid-single-item {
    position: relative;
    height: 300px;
    background-color: #ffffff;

    /****************** overlay *****************/
    &.display-grid:hover .karriere-grid-single-item-overlay {
      height: 100%;
      transition: height 0.5s;
      background-color: transparent;
    }
    &-overlay {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      overflow: hidden;
      width: 100%;
      height: 158px;
      transition: height .5s ease;
      padding: 10px 20px;
      background-color: transparent;
    }
    /******************* END overlay END ***************/

    &-position {
      display: block;
      font: 14px $klavikaBold;
      color: $grey-dark;
      margin: 15px 0px 5px 0;
      background: sassvg('JobsDarkgrey', $grey-dark) no-repeat 0px 2px;
      padding-left: 22px;
    }

    &-name {
      display: block;
      font: 18px $klavikaLight;
      color: $grey-dark;
      margin: 10px 0;
      height: 84px;
      //word-break: break-word;
      &-list {
        font: 18px $klavikaLight;
        color: $grey-dark;
        margin: 10px 0;
        &:hover {
          text-shadow: 0px 0px 1px rgba(49, 49, 49, 1);
        }
      }
    }
    &-divider {
      display: block;
      height: 4px;
      width: 60px;
      background-color: $grey-dark;
      margin: 22px 0 25px 0;
    }
    &-text {
      font: 14px/24px $klavikaLight;
      color: $grey-dark;
      margin: 0;
      & > h2:first-child,
      & > h3:first-child,
      & > p:first-child {
        margin-top: 0;
      }
    }
    &-detail {
      a {
        display: inline-block;
        text-align: left;
        margin-top: 20px;
        padding: 0;
        font-family: $klavikaBold;
        color: $grey-dark;
        font-size: 12px;
        line-height: 15px;
        text-transform: uppercase;
      }
    }
  }
  .karriere-grid-single-letter-headline {
    display: none;
  }
  .karriere-grid-acc-trigger {
    display: none;
    &:after {
      content: "";
      display: block;
      position: absolute;
      right: 40px;
      top: 28px;
      background: sassvg('TabOpenGrey', $grey-dark) center center no-repeat;
      width: 17px;
      height: 17px;
      cursor: pointer;
    }
    &.active {
      &:after {
        background: sassvg('TabCloseGrey', $grey-dark) center center no-repeat;
      }
    }
  }

  /********** display list ***********/
  .karriere-grid-single-holder.sort-list {
    display: block;

    .team-detail-image {
      display: none;
    }
    .karriere-grid-single-item {
      height: auto;
      /****************** overlay *****************/
      &:hover .karriere-grid-single-item-overlay {
        //display: block;
      }
      &-overlay {
        position: relative;
        height: auto;
        width: unset;
        display: none;
        background-color: unset !important;
        padding: 0;
        top: unset;
        left: unset;
        transition: none;
        &-inner {
          height: auto;
          margin: 15px;
          background-color: transparent;
          padding: 10px 20px;
        }
        &:after {
          display: none;
        }
      }
      &-text {
        font: 18px/28px $klavikaLight;
      }
      &-position {
        display: none;
      }
      &-name {
        display: none;
      }
      &-divider {
        display: none;
      }
      /******************* END overlay END ***************/
      .karriere-grid-acc-trigger {
        display: block;
        padding: 10px 60px 10px 10px;
        cursor: pointer;
      }
      .karriere-grid-acc {
        &-text {
          position: relative;
          color: $grey-dark;
          font: 18px $klavikaLight;
          margin: 7px 0px 3px 0px;
        }
        &-header {
          margin: 3px 0px 7px 0px;
          position: relative;
          -webkit-tap-highlight-color: transparent;
          color: $grey-dark;
          font: 14px $klavikaBold;
        }
      }

    }

  }
}


@media only screen and (max-width: 1315px) {
  .karriere-grid {
    .karriere-grid-single-holder {
      grid-template-columns: 300px 300px;
      justify-content: center;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .karriere-grid {
    .karriere-grid-inner {
      padding: 0 10px;
    }
    .karriere-grid-search-holder {
      grid-template-columns: 1fr;
      grid-row-gap: 30px;
      .header-search {
        min-height: unset;
      }
    }
    .karriere-grid-item {
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 20px;
      &-select {
        margin-left: unset;

        &:last-child {
          margin-right: 0;
        }
        .header-search-form-input {
          width: calc(100% - 20px);
          //width: 100%;
          max-width: none;
        }
      }
    }
    .karriere-grid-single-item {
      &-overlay {
        height: 100%;
      }
    }
    .team-grid-sword-wrapper {
      display: flex;
    }
    .select2.select2-container {
      width: 100%;
      max-width: unset;
    }
    select {
      width: 100%;
    }
    .header-search-form-input {
      width: 93%;
      max-width: 310px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .karriere-grid {
    .karriere-grid-single-holder {
      grid-template-columns: 300px;
    }
    .header-search-form .btn-search {
      right: -7px;
    }
  }
}

@media only screen and (max-width: 640px){
  /********** display list ***********/
  .karriere-grid {
    .karriere-grid-item {
      justify-self: unset;
      //.header-search-form-input {
      //  width: calc(100% - 20px);
      //}
      &-select {
        //width: 45%;
        float: none;
        margin-right: 0;
      }
      select {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .karriere-grid {

    .karriere-grid-wrapper {
      margin: 80px auto;
    }

    .karriere-grid-inner {
      max-width: $max-width;
      margin: 0 auto;
    }
    .karriere-grid-item {
      &-select {
        float: none;
        margin-right: 0;
      }
      select {
        width: 100%;
      }
    }
    .select2.select2-container {
      min-width: 100px;
    }
    .karriere-grid-single-holder {
      grid-template-columns: 1fr;
    }
  }
}

