$unsere-cubes-size: 300px;
$unsere-cubes-height: 300px;
$unsere-cubes-size-mobile: 188px;
$unsere-cubes-height-mobile: 188px;

.unsere-cubes {

  .unsere-cubes-inner {
    padding: 70px 0 160px 0;
    max-width: $desktop-width;
    margin: 0 auto;
  }
  .unsere-cubes-boxes {
    display: grid;
    grid-template-columns: $unsere-cubes-size $unsere-cubes-size $unsere-cubes-size;
    grid-gap: 20px;
    justify-content: center;
    a.unsere-cubes-link:hover {
      text-decoration: none;
      .unsere-cubes-box-divider {
        transition: bottom 0.2s;
        bottom: -12px;
      }
    }
    & > a:nth-child(even) > div.unsere-cubes-box {
      background-color: #DFE3E6;
    }
    & > a:nth-child(odd) > div.unsere-cubes-box {
      background-color: $grey-lighter;
    }
  }
  .unsere-cubes-box {
    width: $unsere-cubes-size;
    height: $unsere-cubes-height;
    display: flex;
    &-layer {
      position: relative;
      align-self: center;
      margin: auto;
    }
    &-icon {
      text-align: center;
      img {
        width: 65px;
        height: 65px;
      }
    }
    &-text {
      color: $grey;
      text-align: center;
      margin: 7px 0;
      font: 18px $klavikaBold;
    }
    &-divider {
      position: absolute;
      bottom: -2px;
      left: 50%;
      transform: translateX(-50%);
      height: 2px;
      width: 40px;
      background-color: $grey;
      margin: auto;
      transition: bottom 0.2s;
    }
  }
  .unsere-cubes-smalltitle {
    text-align: center;
    font: 18px $klavikaBold;
    color: $grey-dark;
  }
  .unsere-cubes-headline {
    text-align: center;
    font: 28px $klavikaLight;
    color: $grey-dark;
    margin-top: 5px;
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 1023px) {
  .unsere-cubes {

    .unsere-cubes-wrapper {

    }

    .unsere-cubes-inner {

    }
    .unsere-cubes-boxes {
      grid-template-columns: $unsere-cubes-size $unsere-cubes-size;
      & > a:nth-child(even) > div.unsere-cubes-box {
        background-color: $grey-lighter;
      }
      & > a:nth-child(odd) > div.unsere-cubes-box {
        background-color: $grey-lighter;
      }

      & a:nth-child(2) > div.unsere-cubes-box,
      & a:nth-child(3) > div.unsere-cubes-box,
      & a:nth-child(7) > div.unsere-cubes-box,
      & a:nth-child(8) > div.unsere-cubes-box {
        background-color: #DFE3E6;
      }
    }
    .unsere-cubes-box {
      &-img {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 640px) {
  .unsere-cubes {

    .unsere-cubes-boxes {
      grid-gap: 0;
    }
  }
}

@media only screen and (max-width: $mobile) {
  .unsere-cubes {

    .unsere-cubes-boxes {
      grid-template-columns: $unsere-cubes-size-mobile $unsere-cubes-size-mobile;
    }
    .unsere-cubes-box {
      height: $unsere-cubes-size-mobile;
      width: $unsere-cubes-size-mobile;
    }
  }
}

@media only screen and (max-width: $smallDevices) {
  .unsere-cubes {

    .unsere-cubes-boxes {
      grid-template-columns: 1fr 1fr;
    }

    .unsere-cubes-box {
      width: auto;
    }
  }
}
