@import "constants/_constants";

.blogadmin--container {
  max-width: 905px;
  width: 100%;
  margin: 60px auto;

  //display: grid;
  //align-content: center;
  //justify-content: center;

  h2 {
    text-align: center;
  }

  &--back {
    margin-top: -50px;
    margin-bottom: 50px;

    a {
      font: inherit;
      font-family: KlavikaLight, Arial, sans-serif;
      font-size: 16px;
    }
  }

  &--btn {
    width: 100px;
    margin-bottom: 20px;

    a {
      display: inline-block;
      width: 100%;
      font-size: 17px;
    }
  }

  input {
    height: auto;
  }

  &--form-field {
    display: grid;
    margin-bottom: 20px;
    border-bottom: 2px solid #f4f6f8;


    input, textarea {
      width: 100%;
      display: inline-block;
      border-radius: 2px;
      border: 1px solid #f0f2f1;
      background: #f4f6f8;
      height: auto;
      padding: 5px;
      font-size: 18px;
    }
  }


  &--form-field-two-col {
    display: grid;
    grid-template-columns: 150px 1fr;
    margin-bottom: 20px;
    border-bottom: 2px solid #f4f6f8;
  }

  &--form-field-authors {
    .form-field--multicheckbox {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &--form-field-related {
    .form-field--multicheckbox {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  label {
    font-size: 16px;
  }

  .form-field--multicheckbox {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    label {
      cursor: pointer;

      display: flex !important;
      align-items: center;
      line-height: 1.2;
      font-size: 14px;
      width: auto;
      margin-right: 5px;
      margin-top: 0;
      margin-bottom: 8px;
      padding: 4px 8px;
      background: #f4f6f8;
      text-transform: unset;
      border-radius: 3px;

      input[type="checkbox"] {
        vertical-align: middle;
        margin-right: 5px;
      }
    }
  }

  .blogadmin--container--form-field--submit {
    text-align: center;
  }

  input[type="submit"],
  .blogadmin--container--btn {
    padding: 15px 35px;
    height: auto;
    width: auto;
    background: $blue-dark;
    cursor: pointer;
    color: #fff;
    border: none;

    a {
      color: #fff;
      text-decoration: none;
      font: inherit;
      font-family: KlavikaLight, Arial, sans-serif;
    }
  }

  input[type="date"] {
    max-width: 140px;
    font-size: 18px !important;
  }

  &--list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1280px;
    margin-bottom: 3px;
    border-bottom: 1px solid #f4f6f8;

    p {
      margin: 0;
      width: 250px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .tox .tox-edit-area__iframe {
    background: #f4f6f8 !important;
    padding: 0 10px !important;
  }

}


// Formatting for RTE editor
.mce-content-body {
  a {
    text-decoration: underline;
  }
}