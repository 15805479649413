.main-slider {

  .main-slider-wrapper {
    //padding: 60px 0;
  }

  .main-slider-inner {
    margin: auto;
  }
  .main-slider-holder {
    opacity: 0;
    position: relative;
  }
  .main-slider-position {
    max-width: $max-width;
    margin: auto;
  }
  .main-slider-box {

    &:before {
      content: '';
      padding-top: 100%;
      float: left;
    }
    position: absolute;
    width: 360px;
    aspect-ratio: 1;
    justify-items: center;
    background-color: $blue-transparent;
    top: 20%;
    $square-size: 28px;
    //&:after {
    //  position: absolute;
    //  display: block;
    //  background-color: $red;
    //  content: '';
    //  width: 76px;
    //  height: 76px;
    //  bottom: -76px;
    //  right: -76px;
    //  clear: both;
    //}
    &-overlay {
      position: relative;
      padding: 30px;
      width: 100%;
    }

    &-title {
      font: 38px/40px $klavikaLight;
      color: #ffffff;
      margin: 0 0 15px 0;

      sup {
        font-size: 0.35em;
      }
    }
    &-divider {
      height: 4px;
      width: 60px;
      background-color: #FFFFFF;
      margin-bottom: 60px;

    }
    &-text {
      font: 18px/24px $klavikaLight;
      color: #ffffff;
      margin-bottom: 0px;
    }
  }
  .main-slider-slick-wrapper {
    a:hover {
      text-decoration: none;
    }
  }

  .slick-img-desktop {
    display: block;
  }
  .slick-img-mobile {
    display: none;
  }
  .slick-dots {
    position: absolute;
    bottom: 100px;
    right: 100px;
    display: flex;
    & > li {
      margin-right: 10px;
    }
    button {
      padding: 0;
      height: 4px;
      width: 100%;
      border-radius: 0;
    }
    li {
      background-color: $white;
      height: auto;
      width: 60px;
      &.slick-active {
        background-color: $blue;
      }
    }
  }
  .slick-list {
    padding: 0 0 80px 0;
  }
  .slick-arrow {
    top: 40%
  }
}

@media only screen and (max-width: 1024px) {
  .main-slider {

    .main-slider-wrapper {

    }
    .main-slider-position {
      max-width: 800px;
      margin: 0 50px;
    }

    .main-slider-box {
      width: 320px;
      aspect-ratio: 1;
      height: auto;
      transform: unset;
      top: 3%;
      &-title {
        font: 36px/38px $klavikaLight;
      }
    }

  }
}

@media only screen and (max-width: 767px) {
  .main-slider {

    .slick-img-desktop {
      display: none;
    }
    .main-slider-position {
      margin: 0;
      margin-right: 60px;
    }
    .slick-img-mobile {
      display: block;
    }

    .main-slider-wrapper {
      display: flex;
      flex-flow: column;
    }

    .main-slider-inner {
      width: 100%;
    }
    .slick-arrow {
      top: 40%;
    }
    .main-slider-box {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin-left: unset;
      margin-top: unset;
      &-title {
        font-size: 30px;
        line-height: 32px;
      }
      &-overlay {
        padding: 25px;
      }
      &-divider {
        margin-bottom: 50px;
      }
      &:after {
        width: 50px;
        height: 50px;
        bottom: -50px;
        right: -50px;
      }
    }
    .slick-list {
      padding: 0;
    }

    .main-slider .main-slider-box-overlay:after {
      display: block;
      position: absolute;
      bottom: -28px;
      right: -28px;
      background-color: #d50032;
      height: 28px;
      width: 28px;
      content: "";
    }

    .slick-dots {
      top: 20px;
      bottom: unset;
      right: unset;
      left: 50%;
      transform: translateX(-50%);
    }

  }
}

@media only screen and (max-width: 600px) {
  .main-slider {
    .main-slider-box {
      height: 260px;
      width: 260px;

      &-divider {
        margin-bottom: 30px;
      }

      &-title {
        & p {
          margin: 10px 0;
        }
      }
    }
  }
}
