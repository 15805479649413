.breadcrumb-holder {
  position: absolute;
  bottom: -10px;
  left: 105%;
}

.breadcrumb {
  display: flex;

  li {
    display: flex;
    position: relative;
    margin-right: 40px;
    align-items: center;
    white-space: nowrap;
    color: $grey-light;
    font: 12px $klavikaLight;
    line-height: 26px;

    &:after {
      position: absolute;
      right: -30px;
      top: 50%;
      transform: translateY(-50%);
      width: 24px;
      height: 16px;
      background: sassvg('ArrowWhite', $grey-dark) no-repeat center;
      background-size: 12px;
      display: block;
      content: '';
    }

    &.active {
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 300px;
      display: block;

      &:after {
        display: none;
      }
    }

  }
}


@media only screen and (max-width: $responsive) {
  .breadcrumb-holder {
    display: none;
  }
}